.onboard_page .mt-10 {
    margin-top: 10px;
  }
  a {
    text-decoration: none;
  }
  img {
    max-width: 100%;
  }
 .onboard_page .width-100 {
    width: 100%;
  }
 .onboard_page .onboarding_container {
    max-width: 500px;
    margin: 0 auto;
    height: 100vh;
    background-color: rgb(245, 247, 249);
  }
 .onboard_page .btn_box {
    padding: 20px;
  }
 .onboard_page .btn {
    background-color: rgb(76 75 152);
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5em;
    padding: 12px 24px;
    transition: 0.2s;
    width: 100%;
    cursor: pointer;
    display: inline-block;
    text-align: center;
  }
  
 .onboard_page .inner {
    height: calc(100% - 88px);
    overflow-y: scroll;
  }
 .onboard_page .text_inner {
    padding: 23px 20px;
  }
 .onboard_page .h-1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 118%;
    color: rgb(36, 35, 76);
    margin-top: 15px;
  }
  
 .onboard_page .welcome_text_box {
    display: flex;
    align-items: flex-end;
  }
 .onboard_page .welcome_text_box p {
    font-weight: 700;
    font-size: 17px;
    color: #f16424;
    margin-right: 10px;
  }
 .onboard_page .welcome_text_box img {
    max-width: 160px;
  }
 .onboard_page .step2_imgbox {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(229, 231, 235);
    border-radius: 8px 8px 0px 0px;
  }
 .onboard_page .step2_imgbox > p {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0px 16px 8px;
    margin-top: -24px;
  }
 .onboard_page .step2_imgbox > p span {
    font-weight: 700;
    font-size: 10px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: rgb(36, 35, 76);
  }
 .onboard_page .disclaimer-box {
    padding: 16px;
    border-radius: 0px 0px 8px 8px;
    background: rgb(238, 238, 255);
  }
 .onboard_page .disclaimer-text {
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    color: rgb(36, 35, 76);
  }
 .onboard_page .step_3h {
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: rgb(36, 35, 76);
    text-align: center;
    margin-bottom: 24px;
  }
  
 .onboard_page .almost_there_list {
    display: flex;
  
    justify-content: space-between;
  
    align-items: center;
  
    position: relative;
  
    background-color: rgb(255, 255, 255);
  
    border: 1px solid #f5f5f5;
  
    padding: 20px 19px;
  
    margin-bottom: 20px;
  
    border-radius: 5px;
  
    cursor: pointer;
  }
  
 .onboard_page .almost_there_list h5 {
    color: #24234c;
  
    font-size: 16px;
  
    margin-bottom: 10px;
  }
  
 .onboard_page .almost_there_list input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
  
 .onboard_page .almost_there_list:has(input:checked) {
    background-color: #eeeeff;
    border-color: #b6b6ff;
  }
  
 .onboard_page .almost_there_list .almostThere_content {
    width: 85%;
  }
  
 .onboard_page .almostThere_content > p {
    font-size: 15px;
    color: rgb(107, 114, 128);
  }
  
 .onboard_page .almost_there_list:last-child {
    margin-bottom: 80px;
  }
 .onboard_page .header {
    display: flex;
    position: sticky;
    top: 0px;
    z-index: 40;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    height: 64px;
    padding: 0px 24px;
    background-color: rgb(245, 247, 249);
    max-width: 500px;
    margin: 0 auto;
  }
  
 .onboard_page .learning_path_container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    background-color: rgb(245, 247, 249);
    padding: 40px 24px;
  }
 .onboard_page .pick_h {
    font-size: 29px;
    color: #24234c;
    font-weight: 700;
    margin-bottom: 8px;
  }
 .onboard_page .pick_text {
    color: #6b7280;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 20px;
  }
 .onboard_page .path_box {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 5px 5px;
    padding: 24px 16px;
    background: rgb(255, 255, 255);
    margin-bottom: 20px;
  }
 .onboard_page .path_box .img_box {
    min-height: 134px;
    position: relative;
  }
 .onboard_page .path_box  .img_box p {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #5653fe;
    font-weight: 600;
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: inline-flex;
    align-items: center;
  }
 .onboard_page .path_box .img_box p svg {
    margin-left: 10px;
  }
  
 .onboard_page .path_box .img_box img {
    object-fit: cover;
  }
 .onboard_page .path_box .progres_inner {
    margin-top: 10px;
  }
 .onboard_page .path_box h5 {
    font-size: 1.5rem;
    line-height: 2.25rem;
    font-weight: 600;
    margin: 11px 0;
  }
 .onboard_page .path_box .progres_inner p {
    font-size: 14px;
    color: #6b7280;
    font-weight: 500;
    margin: 5px 0;
  }
 .onboard_page .path_box .progres_inner .progres_bar {
    position: relative;
    width: 100%;
    border-radius: 19px;
    height: 14px;
    background-color: #e4f2db;
  }
 .onboard_page .path_box .progres_inner .progres_bar .progres_line {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 19px;
    background-color: #7abf4c;
    height: 100%;
    transition: width 0.25s linear;
  }
  
 .onboard_page .heading_sunbText {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: rgb(36, 35, 76);
    margin-bottom: 32px;
  }
 .onboard_page .my-5 {
    margin: 23px 0 3px;
  }
 .onboard_page .content_box {
    min-height: 700px;
    background-color: #ffffff;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
  }
 .onboard_page .content_box .text_box {
    text-align: center;
  }
 .onboard_page .content_box .text_box > span {
    padding: 6px;
    background: rgb(86, 83, 254);
    border-radius: 4px;
    width: fit-content;
    font-weight: 600;
    font-size: 12px;
    line-height: 11px;
    color: rgb(255, 255, 255);
  }
 .onboard_page .content_box .text_box h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    color: rgb(36, 35, 76);
    margin: 17px 0 7px;
  }
 .onboard_page .content_box .text_box .chapter_descrip {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: rgb(107, 114, 128);
    margin: 16px 0px;
  }
  
 .onboard_page .dots {
    display: flex;
    justify-content: center;
  }
  
 .onboard_page .dots li button {
    font-size: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0 8px;
    background-color: rgb(200, 206, 215);
    border: 0;
  }
 .onboard_page .dots li.slick-active button {
    background-color: rgb(76 75 152);
  }
 .onboard_page .header button {
    border: 0;
    background-color: transparent;
    cursor: pointer;
  }
 .onboard_page .investing_path_header {
    width: 100%;
    max-width: 500px;
    min-height: 244px;
    margin: 0 auto;
    background-color: #f5f7f9;
    position: relative;
  }
 .onboard_page .investing_path_header button {
    width: fit-content;
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
 .onboard_page .investing_path_header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
 .onboard_page .investing_pathContainer {
    max-width: 500px;
    margin: 0 auto;
    padding: 24px;
  }
 .onboard_page .investing_path_Subh {
    color: #6b7280;
    margin-bottom: 30px;
  }
 .onboard_page .investing_path_h {
    font-size: 32px;
    margin-bottom: 10px;
    color: #24234c;
  }
 .onboard_page .investing_topic {
    font-size: 20px;
    font-weight: 700;
    margin: 10px 0;
    color: #24234c;
  }
 .onboard_page .course_list li {
    padding: 14px;
    display: flex;
    align-items: center;
    background: #f5f7f9;
    border-radius: 8px;
    border: 2px solid #f5f7f9;
    cursor: pointer;
    margin: 18px 0;
  }
 .onboard_page .course_list li .svg_box {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #d0d5e5;
    margin-right: 20px;
  }
 .onboard_page .course_list li .svg_box svg {
    fill: #a6b0d2;
  }
 .onboard_page .course_list_content p {
    margin-bottom: 5px;
    color: #6b7280;
  }
 .onboard_page .course_list_content h4 {
    font-size: 18px;
    color: #24234c;
  }
 .onboard_page .course_list li.active {
    border-color: #5653fe;
  }
 .onboard_page .course_list li.active .svg_box {
    background-color: #5653fe;
  }
 .onboard_page .course_list li.active .svg_box svg {
    fill: #ffffff;
  }
 .onboard_page .course_list li.complete .svg_box {
    background-color: #7abf4c;
  }
  
 .onboard_page .course_list li.complete .svg_box svg {
    fill: #ffffff;
  }
 .onboard_page .learning_paths_list {
    display: flex;
    margin: 30px 0 18px;
  }
 .onboard_page .learning_paths_list li {
    font-size: 20px;
    margin-right: 12px;
    font-weight: 600;
    color: #a6aab9;
    cursor: pointer;
  }
  
  /* new */
 .onboard_page .learning_paths_list li.active {
    color: #5653fe;
    text-decoration: underline;
  }
 .onboard_page .mini_course_box {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 5px 5px #00000008;
    padding: 16px;
    display: flex;
    align-items: center;
    margin: 15px 0;
  }
 .onboard_page .mini_course_box .img_box {
    margin-right: 10px;
  }
 .onboard_page .mini_course_box .img_box img {
    max-width: 44px;
  }
 .onboard_page .mini_course_box .text_box h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5rem;
    color: #24234c;
  }
  
  /* popup*/
 .onboard_page .upsell_popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #666666cc;
    display: flex;
    justify-content: center;
    align-items: end;
    overflow-y: hidden;
    display: none;
  }
  
 .onboard_page .upsell_popup.upsell_popup_active {
    display: flex;
  }
  
 .onboard_page .upsell_popup .popup_inner {
    width: 100%;
    max-width: 500px;
    background-color: #fff;
    padding: 27px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: all 0.5s ease-in-out;
  }
  
 .onboard_page .upsell_popup .popup_inner h3 {
    font-size: 25px;
    text-align: center;
    margin-bottom: 10px;
  }
 .onboard_page .upsell_popup .popup_inner h3 + p {
    text-align: center;
    margin-bottom: 10px;
  }
 .onboard_page .upsell_popup .popup_inner h3 + p span {
    color: #4542f6;
  }
 .onboard_page .upsell_popup .popup_inner .img_box {
    text-align: center;
    padding: 15px 0;
  }
 .onboard_page .descrip_box {
    text-align: center;
    background-color: #f1f1f1;
    margin: 10px 0;
    padding: 15px;
    border-radius: 10px;
  }
 .onboard_page .descrip_box p span {
    color: #4542f6;
  }
  @media (max-width: 500px) {
   .onboard_page .upsell_popup br {
      display: none;
    }
   .onboard_page .upsell_popup .popup_inner h3 + p {
      font-size: 14px;
    }
   .onboard_page .descrip_box p {
      font-size: 14px;
    }
  }
  /* popup*/
 .onboard_page .sub_btn {
    margin-top: 10px;
    background-color: rgb(242 242 242);
    color: #474747ee;
  }
  

.container {
  max-width: 500px;
  margin: 0 auto;
  padding: 2rem 1.5rem 1.5rem;
}
img {
  max-width: 100%;
}
/* login_box */
.input_box {
  margin-top: 28px;
}
.login_box > h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 10px;
}
.input_box input {
  width: 100%;
  font-size: 16px;
  padding: 16.5px 30px 16.5px 14px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  font-family: "Inter", sans-serif;
}
.input_box input:hover {
  border-color: #aaaaaa;
}
.input_box input:focus {
  outline: none;
  border-color: #0e5cb2;
}
.input_box:has(input:focus) label {
  color: #0e5cb2;
}
.input_box input::placeholder {
  color: #c4c4c4;
}
.input_box {
  position: relative;
}
.input_box .error_text {
  font-size: 13px;
  color: rgb(230, 40, 40);
  margin: 5px;
  /* display: none; */
}
.input_box label {
  position: absolute;
  top: -10px;
  left: 14px;
  background: #fff;
  padding: 0 5px;
  font-size: 13px;
  color: #b8b8b8;
}
.input_box input.error + .error_text {
  display: block;
}
.input_box:has(input.error) label {
  color: rgb(230, 40, 40);
}

.input_box input.error {
  border-color: rgb(230, 40, 40);
  outline: 0.5px rgb(230, 40, 40);
}
.input_box i {
  color: #b1b1b1;
  position: absolute;
  right: 10px;
  top: 20px;

  cursor: pointer;
  display: inline-block;
}
.forget_password_text {
  float: right;
  margin: 10px 0;
  border: transparent;
  background-color: transparent;
  border: none;
  color: rgb(86, 83, 254);
  cursor: pointer;
}
.login_form {
  overflow: hidden;
}
.Sign_inbtn {
  margin: 25px 0;
}
.login_subtext {
  font-size: 14px;
  line-height: 28px;
  margin-top: 5px;
  font-weight: 400;
  text-align: center;
}
.login_subtext a {
  text-decoration: underline;
}
.login_box > .reset_password {
  font-size: 16px;
  color: rgb(149, 162, 175);
  line-height: 1.5em;
}
/*//login_box */

/* guides_box */
.guides_box > h2 {
  font-size: 20px;
  color: #24234c;
}
.guides_box > p {
  color: #6b7280;
  font-size: 14px;
  margin-top: 10px;
}
.ongoing_guide {
  border: 1px solid #e5e7eb;
  padding: 16px;
  margin-top: 28px;
  border-radius: 5px;
}
.ongoing_guide img {
  max-width: 80px;
}
.ongoing_list {
  padding: 10px 0 10px;
  display: flex;
  align-items: center;
}
.ongoing_list li {
  display: inline-flex;
  font-size: 13px;
  color: #6b7280;
  align-items: center;
}
.ongoing_list li:not(:first-child) {
  padding-left: 7px;
}
.ongoing_list li span {
  display: inline-block;
  width: 3px;
  height: 3px;
  background-color: #6b7280;
  border-radius: 50%;
  margin-right: 8px;
}
.ongoing_guide > h3 {
  font-size: 20px;
  line-height: 1.5em;
  color: #24234c;
}
.ongoing_guide > p {
  color: #6b7280;
  font-size: 14px;
  margin: 10px 0;
}
.btn {
  background-color: #5653fe;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5em;
  padding: 12px 24px;
  transition: 0.2s;
  width: 100%;
  cursor: pointer;
  text-align: center;
}
.progress_bar {
  width: 100%;
  height: 7px;
  background-color: #e5e7eb;
  margin: 16px 0;
  border-radius: 3px;
  overflow: hidden;
}
.progress_line {
  width: 30%;
  height: 100%;
  background-color: #19aa32;
}
/*//guides_box */
/* recommended_box */
.recommended_box {
  margin: 40px 0;
}
.heading_box {
  margin-bottom: 23px;
}
.heading_box > h3 {
  font-size: 20px;
  color: #24234c;
}
.heading_box > p {
  color: #6b7280;
  font-size: 14px;
  margin-top: 10px;
}
.recommended_item {
  padding: 16px 12px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  width: 49%;
  cursor: pointer;
  margin-bottom: 10px;
}
.recommended_item:active {
  background-color: rgba(0, 0, 0, 0.03);
  -webkit-transform: scale(0.97);
  transform: scale(0.97);
}
.recommended_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.recommended_item h5 {
  color: #24234c;
  font-size: 16px;
  line-height: 1.5em;
  margin-top: 16px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
}
.recommended_item img {
  max-width: 64px;
}
.header {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid #e5e7eb;
  position: sticky;
  top: 0;
  background-color: #fff;
}
.header img {
  max-width: 223px;
}
/*//recommended_box */
/* step2 */
.step2_header {
  max-width: 500px;
  margin: 0 auto;
  padding: 10px 0;
  border-bottom: 1px solid #e5e7eb;
  position: sticky;
  top: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  z-index: 9999;
}
.step2_header .left_arrow {
  padding: 10px 26px;
}
.step2_header .left_arrow i {
  font-size: 17px;
  color: #48484a;
  cursor: pointer;
}
.step2_header h4 {
  font-size: 1rem;
  font-weight: 600;
  width: 68%;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lesson_header {
  text-align: center;
}
.lesson_header > img {
  max-width: 104px;
}
.lesson_duration ul {
  display: flex;
  justify-content: center;
}
.lesson_duration ul li {
  color: #6b7280;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
.lesson_duration ul li span {
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: #6b7280;
  border-radius: 50%;
  margin: 0 8px;
}
.lesson_heading {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 8px;
  padding: 0 16px;
  color: #24234c;
}
.lesson_subHeading {
  color: #6b7280;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5em;
  margin-top: 8px;
}
.progress_barScore {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.progress-score {
  font-size: 12px;
  margin-left: 9px;
}
.container_large {
  max-width: 500px;
  margin: 0 auto;
  padding: 2rem 0.3rem 1.5rem;
}
.lesson-unit {
  margin: 15px 0;
}
.lesson_unitHeader {
  display: flex;
  justify-content: space-between;
  background-color: #f5f7f9;
  border: 1px solid #e5e7eb;
  border-left: none;
  border-right: none;
  color: #24234c;
  padding: 13px 16px;
  cursor: pointer;
}
.lesson_unitHeader p {
  font-weight: 600;
}
.unitlesson_box {
  /* min-height: 0;
  max-height: 0; */
  overflow: hidden;
  /* transition: all .5s ease-in-out; */
}
.lesson_checkBox {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0.5rem 1rem 1rem;
  cursor: pointer;
  flex-wrap: wrap;
  margin: 16px 10px;
}
.lesson_checkBox .lesson_imgBox img {
  display: inline-block;
  max-width: 72px;
  margin: 0 10px;
}
.lesson_check_content h6 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5em;
  overflow: hidden;
  text-align: left;
  color: #24234c;
}
.lesson_check_content span {
  color: #6b7280;
  font-size: 13px;
}
.lesson_checkBox .checkMark_box input[type="radio"] {
  width: 15px;
  height: 15px;
  accent-color: #534fff;
}
.lesson-unit .start_lesson_btn {
  font-size: 14px;
  margin-top: -4px;
  display: none;
}
.lesson-unit .start_lesson_btn:active {
  background-color: rgba(81, 78, 243, 0.9);
  -webkit-transform: scale(0.99);
  transform: scale(0.99);
}
.lesson_checkBox:has(input[type="radio"]:checked) {
  border-color: #534fff;
}
.lesson_checkBox:has(input[type="radio"]:checked) .start_lesson_btn {
  display: block;
}
.lesson_checkBox.complete_lessson input[type="radio"] {
  display: none;
}
.lesson_checkBox.complete_lessson .checkMark_box i {
  color: #19aa32;
  font-size: 15px;
}
.lesson_checkBox.lesson_lock .checkMark_box input[type="radio"] {
  display: none;
}
.lesson_checkBox.lesson_lock .checkMark_box > i {
  font-size: 9px;
  color: #fff;
  background-color: #939393;
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.lesson_checkBox.lesson_lock {
  pointer-events: none;
  opacity: 0.8;
}
.lesson-unit .lessonOpen_icon_box > i {
  transition: all 0.5s ease-in-out;
}
.lesson-unit.unit__active .lessonOpen_icon_box > i {
  transform: rotate(180deg);
}
.lesson_container {
  margin-top: 30px;
  margin-bottom: 30px;
}

/* //step2 */
/* step_3 */
.start_lesson_header {
  max-width: 500px;
  margin: 0 auto;
  padding: 10px 0;
  border-bottom: 1px solid #e5e7eb;
  position: sticky;
  top: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 3;
}
.start_lesson_header .closeLesson_iconBox {
  padding: 0 10px;
  font-size: 20px;
  color: #9d9d9d;
}
.lessson_progress_bar {
  width: 390px;
  height: 12px;
  background-color: #e5e7eb;
  border-radius: 30px;
  overflow: hidden;
}
.lessson_progress_line {
  height: 100%;
  background-color: #19aa32;
}
.start_lesson_contentBox {
  padding: 0 12px;
  color: #24234c;
  margin-bottom: 70px;
}
.start_lesson_contentBox > h2 {
  font-size: 29px;
  line-height: 1.4em;
  color: #24234c;
  padding: 20px 0 10px;
}
.start_lesson_contentBox > p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7em;
}
.btn.btn_fix {
  max-width: 440px;
  position: fixed;
  bottom: 20px;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
}
.lesson_content h3 {
  font-weight: 700;
  font-size: 20px;
  margin: 16px 16px 0;
}
.lesson_content p {
  font-size: 16px;
  margin: 16px 16px 0;
  font-weight: 400;
  line-height: 1.7em;
}
/* step_3 */

/* step_5 */
.complete_lessson_box {
  text-align: center;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.complete_lessson_box h4 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 1.5rem;
}
.complete_lessson_box p {
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 400;
  margin-top: 8px;
}
.page_close {
  position: fixed;
  top: 20px;
  left: 20px;
  font-size: 20px;
  color: #565b66;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
/* step_5 */
/* last_step */
.last_step {
  height: 100vh;
  background-color: #f5f7f9;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: -10px;
}
.last_step h4 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  margin-top: 2rem;
  color: #24234c;
}
.emoji_list {
  margin-top: 20px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
}
.emoji_list button {
  align-items: center;
  background: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  display: flex;
  height: 56px;
  justify-content: center;
  transition: 0.3s;
  width: 56px;
  cursor: pointer;
}
.emoji_list img {
  max-width: 30px;
}
.last_step_subtext {
  padding: 10px 19px;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}
/* last_step */
/* navbar */
.navbar {
  display: block;
  width: 500px;
  max-width: 500px;
  border-radius: 10px 10px 0 0;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 -2px 4px #0000000d;
  z-index: 999;
  background-color: #fff;
}
.navbar ul {
  display: flex;
  padding: 10px 28px;
  justify-content: space-around;
}
.navbar ul li a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #a6aab3;
  cursor: pointer;
}
.navbar ul li span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #a6aab3;
}
.navbar ul li a {
  color: #a6aab3 !important;
}
.navbar ul li a.active {
  color: #5653fe !important;
}

.navbar ul li.active {
  color: #5653fe;
}
.navbar ul li p {
  font-size: 10px;
  margin-top: 5px;
}
/*navbar */
/* profile */
.profile_body {
  width: 100%;
  /* height: calc(100vh - 90px); */
  /* height: 100vh; */
  background-color: #f5f7f9;
  padding: 24px;
}
.profile_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  border-bottom: 1px solid rgb(224, 228, 233);
}
.profile_header p {
  font-weight: 600;
}
.profile_header img {
  cursor: pointer;
}
.profile_name h3 {
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  color: rgb(36, 35, 76);
}
.profile_name p {
  font-size: 14px;
  line-height: 21px;
  color: rgb(99, 99, 102);
}
.profile_name {
  margin-bottom: 16px;
}
.profile_menu_item {
  display: flex;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  color: rgb(36, 35, 76);
  font-size: 16px;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 1rem;
  border: 1px solid rgb(229, 231, 235);
  line-height: 21px;
  font-weight: 500;
}
.profile_menu_item .profile_item_icon {
  display: flex;
}
.profile_menu_item .profile_item_icon p {
  text-transform: capitalize;
  margin-left: 10px;
}
.profile_menu_item .profile_item_icon p.danger_text {
  color: rgb(255, 69, 58);
}
.copy_right_text {
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  color: rgb(107, 114, 128);
  text-align: center;
  margin-top: 24px;
}
.padding-0 {
  padding: 0;
}
.padding_top0 {
  padding-top: 0;
}
/* profile */
/* settings */
.settings_menu_item .profile_menu_item {
  margin-bottom: 0;
}
.settings_menu_item:not(:first-of-type) .profile_menu_item {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 0;
}
.settings_menu_item:not(:last-of-type) .profile_menu_item {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
/* settings */

/* create_account */
.create_account_container {
  max-width: 620px;
  margin: 0 auto;

  padding: 0 20px;
}
.create_account_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 48px;
}
.create_account_header > button {
  background-color: #fff;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
.create_account_header > button i {
  font-size: 19px;
  margin-left: 5px;
}
.language_logo {
  position: relative;
}
.language_logo .langauage_dropdwon {
  width: 160px;
  max-width: 160px;
  background-color: #f8fafc;
  border: 1px solid #868686;
  position: absolute;
  top: calc(100% + 10px);
  right: -15px;
  border-radius: 5px;
  padding: 5px;
  display: none;
  z-index: 99;
}
.language_logo .langauage_dropdwon.show {
  display: block;
}
.language_logo .langauage_dropdwon form label input {
  visibility: hidden;
  opacity: 0;
}
.language_logo .langauage_dropdwon form label {
  display: flex;
  align-items: center;
  padding: 3px 0;
  cursor: pointer;
  margin: 5px 0;
}
.language_logo .langauage_dropdwon form label p {
  margin-left: 13px;
  font-weight: 500;
  font-size: 15px;
}
.accounts_status_nav ul {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.accounts_status_nav ul li {
  font-size: 18px;
  color: #919ba8;
  font-weight: 500;
}
.accounts_status_nav ul li.active {
  color: #000;
}
.accounts_status_nav {
  padding: 0 10px;
}
.progress_mark_nav {
  padding: 28px 30px 20px;
}
.progress_mark_nav ul {
  display: flex;
  justify-content: space-between;
}

.progress_mark_nav li {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #d4d9e0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
}
.progress_mark_nav ul li i {
  color: #fff;
  display: none;
}
.progress_mark_nav li.complete_mark {
  background-color: #5653fe;
  border-color: #5653fe;
}
.progress_mark_nav ul li.complete_mark i {
  display: block;
}
.progress_mark_nav ul li:not(:last-child):after {
  content: "";
  width: 134px;
  height: 2px;
  position: absolute;
  left: calc(100% + 2px);
  background-color: #d4d9e0;
  z-index: -1;
}
.progress_mark_nav ul li.progress_active {
  border-color: #5653fe;
}
.progress_mark_nav ul li.progress_active::before {
  content: "";
  width: 17px;
  height: 17px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #5653fe;
}
.progress_mark_nav ul li.line_active:not(:last-child):after {
  background-color: #5653fe;
}
.create_account_fromBox {
  padding: 14px 15px;
}
.create_account_fromBox h3 {
  font-size: 26px;
  color: #24234c;
}
.create_account_fromBox > P {
  text-align: center;
  font-size: 18px;
  line-height: 1.5em;
  margin: 10px 0;
}
.accounts_from_box input {
  width: 100%;
  font-size: 18px;
  margin-bottom: 10px;
  padding: 16px 15px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
}
.accounts_from_box {
  position: relative;
}
.accounts_from_box label {
  font-size: 15px;
  position: absolute;
  top: -10px;
  left: 12px;
  background-color: #fff;
}
.check_box {
  /* margin: 24px 0 10px; */
}
.check_box input {
  width: 20px;
  height: 20px;
}
.check_box label {
  vertical-align: super;
  margin-left: 10px;
  font-size: 16px;
  color: #48484a;
}
.check_box label a {
  color: #48484a;
  text-decoration: underline;
}
.btn_disable {
  pointer-events: none;
  opacity: 0.5;
}
/* create_account */

/* poup */
.almost_finished_poup {
  width: 100vw;
  height: 100vh;
  background-color: #7f7f7f4d;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  display: none;
}
.almost_finished_poup.show_poup {
  display: flex;
}
.almost_finished_poup_body {
  width: 450px;
  max-width: 450px;
  background-color: #fff;
  padding: 28px 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 11px 0px #c8c8c8b5;
}
.poup_close {
  float: right;
  font-size: 23px;
  color: #000;
  cursor: pointer;
}
.almostfinished_textBox {
  padding: 43px 0 0;
  text-align: center;
}
.almostfinished_textBox h2 {
  font-size: 24px;
  color: #24234c;
}
.almostfinished_textBox p {
  font-size: 18px;
  margin: 8px 0 40px;
  color: #686566;
}
.almostfinished_textBox p img {
  vertical-align: middle;
  margin-left: 4px;
}
.confrim_infrom_box {
  background-color: #f5f7f9;
  margin: 23px 0 14px;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.confrim_infrom_box p {
  font-size: 16px;
  margin-left: 10px;
  line-height: 1.3em;
}
.margin-0 {
  margin: 0;
}
.confrim_infrom_box img {
  max-width: 18px;
  height: 19px;
}
/* poup */

/* loader */
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.loader_spin {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader_spin:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #5653fe;
  border-color: #5653fe transparent #5653fe transparent;
  animation: loader_spin 0.8s infinite;
}
@keyframes loader_spin {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
/*  */
/* loader */

/* add_my_plan */
.add_toplan_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 10px;
}
.skip_iconbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.skip_iconbox p {
  margin-right: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #3e444d;
}
.skip_iconbox i {
  color: #3e444d;
}
.add_toPlan_contentBox {
  margin: 12px 0;
  padding: 0 25px;
}
.add_toPlan_contentBox h3 {
  font-size: 21px;
  color: #23224a;
  margin: 18px 0 10px;
}
.recommended_content {
  background-color: #efeefe;
  display: inline-block;
  color: #5652fe;
  font-weight: 500;
  font-size: 14px;
  padding: 6px;
  border-radius: 4px;
  margin: 10px 0 16px;
}
.top_plan_list > li {
  padding: 5px 0;
  font-weight: 400;
}
.top_plan_list > li > i {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #5652fe;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin-right: 3px;
}
.time_count {
  background-color: #efeefe;
  text-align: center;
  width: 100%;
  padding: 20px 4px;
  border-radius: 5px;
  margin: 15px 0;
}
.time_count h4 {
  font-size: 27px;
  margin-bottom: 5px;
}
.time_count h4 img {
  vertical-align: bottom;
}
.time_count > p {
  font-size: 14px;
  line-height: 1.5em;
}
.stories_rating_list {
  overflow: hidden;
  margin: 10px 0;
}
.stories_rating_list li {
  float: left;
}
.stories_rating_list li i {
  color: #f79f1a;
}
.success_stories_header {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.success_stories_header h3 {
  margin: 5px 0;
}
.success_stories_header p {
  font-size: 13px;
  color: #8f8f8f;
}
.stories_box {
  background-color: #f5f5f5;
  padding: 25px 15px;
  border-radius: 7px;
  margin: 15px 0;
}
.stories_name_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.stories_name_header h4 {
  font-size: 16px;
  color: #2a2b4d;
}
.stories_name_header p {
  font-size: 13px;
  color: #19aa31;
  font-weight: 500;
}
.stories_name_header p > i {
  width: 16px;
  height: 16px;
  font-size: 14px;
  background-color: #19aa31;
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
.stories_content > p {
  font-size: 15px;
  line-height: 1.5em;
  margin-top: 13px;
}
.stories_box .stories_rating_list {
  margin-bottom: 0;
}
.stories_footer {
  border: 1px solid #f0f0f3;
  padding: 10px 8px;
  border-radius: 6px;
  font-size: 12px;
  text-align: center;
  color: #9ea4a7;
  font-style: italic;
  line-height: 1.5em;
  margin: 22px 0;
}
.stories_footer p strong {
  color: #6a7285;
}
.btn.btn_light {
  color: #3d2e4b;
  background-color: #f5f5f5;
  margin-top: 15px;
}
.stories_rating_list li:not(:last-child) {
  margin-right: 5px;
}
/*//add_my_plan */

/* add_plan_container */
.addPlan_poup {
  width: 100%;
  height: 100vh;
  background-color: #7f7f7f4d;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
}
.addPlan_poup.show {
  display: block;
}
.addPlan_poupBody {
  background-color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 30px 0;
  transform: translateY(100%);
  transition: transform 0.5s ease-in-out;
}
.addPlan_poup .addPlan_poupBody.show_slide {
  transform: translateY(0%);
}
.addPlan_poupBody::after {
  content: "";
  width: 50px;
  height: 5px;
  background-color: #dbdbdb;
  border-radius: 5px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  cursor: ns-resize;
}
.addPlan_poup_container {
  max-width: 630px;
  margin: 0 auto;
  text-align: center;
}
.addPlan_poup_container > img {
  margin: 16px 0;
}
.addPlan_poup_container > h3 {
  font-size: 30px;
  color: #24234c;
  text-align: center;
  margin-bottom: 20px;
  line-height: 1.4em;
}
.addPlan_poup_container > p {
  font-size: 17px;
  line-height: 1.5em;
  text-align: center;
  color: #3f3e62;
}
.addPlan_poup_footer {
  background-color: #f1f1f1;
  color: #3f3e62;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.addPlan_poup_footer > p {
  font-size: 17px;
  line-height: 1.5em;
}
.addPlan_poup_container > p > a,
.addPlan_poup_footer > p > a {
  font-weight: 600;
  color: #6360fe;
}
/* add_plan_container */
/* offer_list_box */
.offer_list_box h3 {
  color: #242354;
  font-size: 20px;
  margin: 26px 0 21px;
}
.offer_list {
  border: 1px solid #ecedf0;
  display: flex;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}
.offer_list img {
  display: block;
}
.offer_list input {
  position: absolute;
  opacity: 0;
}
.offer_list_content_box {
  padding: 28px 8px 18px 10px;
}
.offer_list_content_box p {
  font-size: 13px;
  font-weight: 700;
}
.offer_price_list {
  overflow: hidden;
}
.offer_price_list li {
  float: left;
  margin-right: 10px;
  font-weight: 600;
}

.offer_price_list {
  margin-top: 10px;
}
.discount_text {
  text-decoration: line-through;
  color: #6d7685;
  font-size: 14px;
}
.offer_active {
  color: #7170fe;
  font-size: 14px;
}
.offer-budge {
  background-color: #efeefe;
  border: 1px solid #8582fe;
  color: #7170fe;
  padding: 2px;
  border-radius: 2px;
  font-size: 12px;
}
.offer_icon_box i {
  width: 18px;
  height: 18px;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  font-size: 12px;
  border: 1px solid #d9dcde;
}
.offer_list:has(input:checked) .offer_icon_box i {
  background-color: #5652fe;
  border-color: #5652fe;
}
.offer_list:has(input:checked) {
  border-color: #5652fe;
}
.offer_icon_box {
  margin-left: 10px;
}
/* offer_list_box */
/* almost_there_page */
.almost_there_heading {
  margin-bottom: 20px;
}
.almost_there_heading h3 {
  color: #242354;
  font-size: 20px;
  margin: 15px 0 10px;
}
.almost_there_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  padding: 20px 19px;
  margin-bottom: 20px;
  border-radius: 5px;
  cursor: pointer;
}
.almost_there_list h5 {
  color: #24234c;
  font-size: 16px;
  margin-bottom: 10px;
}
.almost_there_list input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.almost_there_list:has(input:checked) {
  background-color: #eeeeff;
  border-color: #101010;
}
.almost_there_list .almostThere_content {
  width: 85%;
}
.almostThere_content > p {
  font-size: 15px;
}
.almost_there_list:last-child {
  margin-bottom: 80px;
}
/* almost_there_page */
/* level_info_box */
.level_infoImg_box {
  padding: 0 20px;
}
.infolevel_content {
  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 10px;
  line-height: 1.5em;
  margin-bottom: 30px;
}
/* level_info_box */
/* ai_slider */
.ai_slider {
  border-radius: 5px;
  overflow: hidden;
}
.aiSlider_box {
  position: relative;
}
.aiSlider_box .slick-dots {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.aiSlider_box .slick-dots li {
  float: left;
  margin: 0 5px;
}
.aiSlider_box .slick-dots li button {
  width: 15px;
  height: 6px;
  border-radius: 40px;
  background-color: #5652fe;
  font-size: 0;
  border: 0;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.aiSlider_box .slick-dots li.slick-active button {
  width: 25px;
}
.ai_future_box {
  background-color: #e9f3ff;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 30px;
}
.ai_future_content {
  padding: 20px;
}
.ai_future_content > h2 {
  font-size: 20px;
  color: #242354;
  margin-bottom: 10px;
}
.ai_future_content > p {
  font-size: 15px;
  margin-bottom: 10px;
}

/* ai_slider */

/* responsive */
@media (max-width: 767px) {
  .addPlan_poup_container {
    max-width: 500px;
  }
  .addPlan_poup_container h3 br {
    display: none;
  }
  .addPlan_poup_container > h3 {
    font-size: 23px;
    margin-bottom: 10px;
  }
  .addPlan_poup_container br {
    display: none;
  }
  .addPlan_poup_container > p {
    font-size: 15px;
  }
  .addPlan_poup_footer > p {
    font-size: 15px;
  }
}
@media (max-width: 620px) {
  .create_account_container {
    max-width: 400px;
  }
  .create_account_header {
    padding: 20px 5px;
  }
  .accounts_status_nav ul li {
    font-size: 14px;
    text-align: center;
  }
  .progress_mark_nav {
    padding: 15px 36px 14px;
  }
  .progress_mark_nav ul li i {
    font-size: 14px;
  }
  .progress_mark_nav li {
    width: 25px;
    height: 25px;
  }
  .progress_mark_nav ul li.progress_active::before {
    width: 10px;
    height: 10px;
  }
  .progress_mark_nav ul li:not(:last-child):after {
    width: 64px;
  }
  .create_account_fromBox h3 {
    font-size: 20px;
  }
  .create_account_fromBox > P {
    font-size: 16px;
  }
  .check_box {
    display: flex;
  }
  .check_box label {
    font-size: 15px;
    line-height: 1.5em;
  }

  .new_chatPoup_edit {
    padding: 29px 34px 80px 34px;
  }
}

@media (max-width: 575.98px) {
  .btn.btn_fix {
    max-width: 300px;
  }
  .container {
    max-width: 400px;
  }
  .container_large {
    max-width: 380px;
    padding: 0 10px;
  }
  .login_box > h2 {
    font-size: 19px;
    margin-bottom: 0;
  }
  .input_box input {
    font-size: 15px;
    padding: 13.5px 13px;
  }
  .login_box > .reset_password {
    font-size: 15px;
  }
  .lesson_heading {
    font-size: 19px;
  }
  .lesson_subHeading {
    font-size: 15px;
  }
  .lesson_checkBox {
    margin: 16px 0;
  }
  .lessson_progress_bar {
    max-width: 270px;
  }
  /* .step2_header {
    max-width: 320px;
  } */
  .start_lesson_contentBox > h2 {
    font-size: 24px;
  }
  .start_lesson_contentBox > p {
    font-size: 15px;
  }
  .start_lesson_contentBox {
    padding: 0;
  }
  .last_step h4 {
    font-size: 16px;
    margin-top: 1rem;
  }
  .emoji_list button {
    width: 40px;
    height: 40px;
  }
  .emoji_list img {
    max-width: 20px;
  }
  .step2_header .left_arrow {
    padding: 10px 16px;
  }
  .last_step {
    height: 80vh;
  }
  .navbar {
    max-width: 400px;
  }

  .profile_menu_item .profile_item_icon p {
    font-size: 15px;
  }

  /* poup_css */
  .almost_finished_poup_body {
    max-width: 350px;
    padding: 24px 28px;
  }
  .confrim_infrom_box p br {
    display: none;
  }
  .confrim_infrom_box p {
    font-size: 15px;
  }
  .confrim_infrom_box {
    padding: 15px;
  }
  .almostfinished_textBox p {
    font-size: 15px;
  }
  .almostfinished_textBox h2 {
    font-size: 23px;
  }
  .almostfinished_textBox {
    padding-top: 25px;
  }
  .almost_finished_poup {
    padding: 0 10px;
  }
  /* poup_css */
  /* stories */
  .add_toPlan_contentBox {
    padding: 0;
  }
  .add_toPlan_contentBox h3 {
    font-size: 20px;
  }
  .add_toPlan_contentBox h3 br {
    display: none;
  }
  .top_plan_list > li {
    display: flex;
    font-size: 15px;
  }
  .top_plan_list > li > i {
    margin-right: 5px;
  }
  .time_count h4 {
    font-size: 22px;
  }
  .stories_rating_list li i {
    font-size: 13px;
  }
  .stories_name_header h4 {
    font-size: 15px;
  }
  /* stories */
  /* stories_poup */
  .addPlan_poup_container > img {
    max-width: 300px;
  }
  .addPlan_poup_container {
    max-width: 370px;
    padding: 0 10px;
  }
  .addPlan_poup_container > h3 {
    font-size: 20px;
  }
  .addPlan_poup_container > p {
    font-size: 14px;
  }
  .addPlan_poup_footer > p {
    font-size: 13px;
  }
  /* stories_poup */
  /* offer_list */
  .offer_list_content_box {
    padding: 8px 0px 5px 14px;
  }
  .offer_list_content_box p {
    font-size: 12px;
  }
  .offer_icon_box {
    margin-right: 10px;
    margin-left: 0;
  }
  .offer_list img {
    max-width: 60px;
  }
  .offer_icon_box i {
    width: 14px;
    height: 14px;
    margin-top: 30px;
    font-size: 10px;
  }
  .discount_text {
    font-size: 12px;
  }
  .offer_active {
    font-size: 12px;
  }
  .offer-budge {
    font-size: 10px;
  }
  .offer_list_box h3 {
    font-size: 17px;
  }
  /* offer_list */
  /* almost_there */
  .almost_there_list {
    padding: 19px 15px;
  }
  .almostThere_content > p {
    line-height: 1.5em;
  }
  .almost_there_list img {
    max-width: 25px;
  }
  .almost_there_heading h3 {
    margin: 50px 0 10px;
  }
  .almost_there_heading p {
    line-height: 1.5em;
  }
  /* almost_there */
  /* info_level */
  .infolevel_content {
    font-size: 14px;
    padding: 10px;
  }
  .level_infoImg_box {
    padding: 0 10px;
  }
  /* info_level */
  /* ai_slider */
  .ai_future_content > h2 {
    font-size: 18px;
  }
  .ai_future_content > p {
    font-size: 14px;
    line-height: 1.5em;
  }
  /* ai_slider */
  .new_chatPoup_edit {
    padding: 29px 34px 80px 34px;
  }
}
@media (max-width: 400px) {
  .progress_mark_nav {
    max-width: 260px;
    padding: 0;
    margin: 10px auto;
  }
  .progress_mark_nav ul li:not(:last-child):after {
    width: 58px;
  }
  .progress_mark_nav li {
    width: 22px;
    height: 22px;
  }
  .progress_mark_nav ul li i {
    font-size: 11px;
  }
  .check_box label {
    font-size: 14px;
  }
  .profile_menu_item .profile_item_icon p,
  .profile_menu_item .profile_item_icon p {
    font-size: 13px;
  }
  .confrim_infrom_box p {
    font-size: 12px;
  }
}

.active_content {
  position: relative;
  top: 0;
}

/* / language_section / */
.lang_box_container {
  box-shadow: rgba(0, 0, 0, 0.27) 0px 0px 2px;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
}
.lang_box_container .language_box {
  font-size: 16px;
  color: rgb(36, 35, 76);
  line-height: 1.5em;
  font-weight: 500;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}
.lang_box_container .language_box .lang_imageContentBox {
  display: flex;
}
.lang_imageContentBox h5 {
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
}
.lang_arrowBox i {
  opacity: 0;
  color: #5653fe;
}
.language_box.check_active .lang_arrowBox i {
  opacity: 1;
}
.language_box:not(:last-child) {
  border-bottom: 1px solid rgb(200, 206, 215);
}
/* / language_section / */

/* / reports_css / */
/* .profile_body {
  height: calc(100vh - 65px);
} */
.reports_box {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.27) 0px 0px 2px;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 8px;
  min-height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.reports_box .reports_imageContentBox {
  display: flex;
  align-items: center;
}
.reports_box .reports_imageContentBox h5 {
  font-size: 14px;
  line-height: 1.5em;
  font-weight: 600;
  margin-left: 10px;
}
.reports_box img {
  max-width: 40px;
}
.reports_box .reports_arrowBox {
  font-size: 18px;
  margin-right: 10px;
  color: #48484a;
}
/* / reports_css / */

/* / subcription_css / */
.subcription_body h4 {
  font-size: 20px;
  font-weight: 600;
}
.subcription_dtl_box {
  margin-top: 16px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.27) 0px 0px 2px;
}
.subcription_header {
  padding: 16px;
  border-bottom: 1px solid rgba(200, 206, 215, 0.5);
}
.subcription_header h3 {
  font-size: 16px;
  font-weight: 600;
  color: #24234c;
}
.subcription_body_content ul li {
  display: flex;
  padding: 16px;
  justify-content: space-between;
}
.subcription_body_content ul li p {
  color: rgb(99, 99, 102);
  font-size: 14px;
  line-height: 1.5em;
  padding-right: 5px;
}
.subcription_body_content ul li h5 {
  font-size: 14px;
  line-height: 1.5em;
  font-weight: 700;
  color: rgb(99, 99, 102);
}
.subcription_body_content > h5 {
  padding: 16px;
  font-size: 14px;
  line-height: 1.5em;
  font-weight: 500;
  color: rgb(99, 99, 102);
}
.subcription_body_content {
  overflow: hidden;
}
.subcription_cancele_box {
  margin: 0px 16px 16px;
  background-color: rgb(255, 245, 230);
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  color: rgb(255, 153, 0);
  font-weight: 400;
  font-size: 14px;
}
.subcription_cancele_box i {
  margin-right: 6px;
}
.subcription_footer {
  margin-top: 16px;
  padding: 15px 16px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.27) 0px 0px 2px;
}
.subcription_footer p {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5em;
  margin-bottom: 5px;
}
.reactivate_btn {
  margin-top: 20px;
}
.profile_header i {
  cursor: pointer;
  font-size: 16px;
}
/* / subcription_css / */

/* style added by dk */
.feedback_option {
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}

.feedback_comment {
  margin-top: 1.5rem;
}
.feedback_title {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
}
.feedback_textarea {
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  height: 7.125rem;
  line-height: 1.5;
  margin-top: 1rem;
  padding: 0.75rem;
  resize: none;
  transition: 0.2s;
  width: 100%;
}
.feedback_textarea:focus {
  outline-color: #5653fe;
}
.feedback_comment_counter {
  color: #6b7280;
  font-size: 0.75rem;
  font-weight: 400;
  margin-top: 0.25rem;
  text-align: right;
}
/* end style added by dk */

/* task box css*/

.task_box {
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  margin: 1.5rem 1rem 0;
  padding: 1rem;
  font-family: "Inter", sans-serif;
}
.guide-lesson-task__title {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
  color: #24234c;
}
.guide-lesson-task__text {
  text-wrap: pretty;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 0.5rem;
  white-space: pre-line;
}
.guide-lesson-task__actions {
  margin-top: 1rem;
}
.task-skipped {
  background-color: #e5e7eb;
  color: #6b7280;
}
.task-completed,
.task-skipped {
  align-items: center;
  border-radius: 0.125rem;
  display: flex;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5;
  padding: 0.125rem 1rem 0 0.5rem;
  width: -webkit-max-content;
  width: max-content;
}
.task-completed > * + *,
.task-skipped > * + * {
  margin-left: 0.25rem;
}
.guide-lesson-task__actions {
  margin-top: 1rem;
}
.ui-primary-button {
  background-color: #5653fe;
  border: none;
  border-radius: 0.375rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  padding: 0.75rem 1.5rem;
  transition: 0.2s;
  width: 100%;
}
.task-completed {
  background-color: #f2f9ed;
  color: #19aa32;
}
/* task box css end */

/* start css quiz */
.question_container {
  margin: 0 auto;
  max-width: 500px;
}

.gray_box {
  background-color: #f5f7f9;
  padding: 1.5rem;
  font-family: Inter, Arial, Helvetica, sans-serif;
  margin-top: 28px;
}

.gray_box .qn_hding {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  color: #24234c;
}

.gray_box .mcq_answers_ul {
  margin-bottom: 2rem;
  margin-top: 1rem;
  color: #24234c;
  padding-left: 0;
}

.gray_box .limcq_answers_li {
  list-style: none;
  padding: 0.5rem 0;
}

.gray_box .limcq_answers_li label {
  align-items: center;
  display: flex;
}

.gray_box .limcq_answers_li label > * + * {
  margin-left: 0.5rem;
}

.gray_box .mcq_answers_ul > * + * {
  margin-top: 0.5rem;
}

.gray_box .mcq_answers_ul li:first-child {
  margin-top: none;
}

.gray_box .limcq_answers_li label .mcq_answers_text {
  flex: 1 1;
  font-size: 0.875rem;
  line-height: 1.5;
}

.gray_box .quiz-checkmark-correct {
  fill: #19aa32;
  color: #19aa32;
}

.gray_box .mcq_option__checkmark_blank {
  background-color: #fff;
  border: 1px solid #c4c7cc;
  border-radius: 9999px;
  height: 19px;
  position: relative;
  transition: 0.2s;
  width: 19px;
  z-index: 0;
}

.gray_box .mcq_option__checkmark_blank_nrm_check {
  background-color: #fff;
  border: 1px solid #c4c7cc;
  border-radius: 9999px;
  height: 20px;
  position: relative;
  transition: 0.2s;
  width: 20px;
  z-index: 0;
}

.gray_box .mcq_option__checkmark_blank svg {
  margin-top: -1px;
  margin-left: -1px;
  display: none;
}

.gray_box .limcq_answers_li label {
  position: relative;
}

.gray_box .limcq_answers_li label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.gray_box
  .limcq_answers_li
  label
  input[type="radio"]:checked
  + .mcq_option__checkmark_blank
  svg {
  display: block;
}

.gray_box
  .limcq_answers_li
  label
  input[type="radio"]:checked
  + .mcq_option__checkmark_blank_nrm_check {
  border-color: #5653fe;
}

.gray_box
  .limcq_answers_li
  label
  input[type="radio"]:checked
  + .mcq_option__checkmark_blank_nrm_check:after {
  background-color: #5653fe;
  border-radius: 9999px;
  content: "";
  height: 10px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
}

.gray_box .limcq_answers_li label input[type="radio"] {
  width: 15px;
  height: 15px;
  accent-color: #534fff;
}

/* .gray_box .mcq_answers_ul.after_check_choose_option label input {
  display: none;
} */

.gray_box .correct_congo {
  border-left: 2px solid;
  margin-top: 2rem;
  padding: 0.25rem 0 0.25rem 1rem;
  border-color: #19aa32;
}

.gray_box .correct_congo_hd {
  align-items: start;
  display: flex;
  margin: 0;
}

.gray_box .correct_congo__title {
  flex: 1 1;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5;
  color: #24234c;
  margin: 0;
}

.gray_box .correct_congo__text {
  font-size: 1rem;
  line-height: 1.7;
  margin-top: 1rem;
  white-space: pre-line;
}

.gray_box .correct_congo_hd svg {
  margin-right: 0.5rem;
  margin-top: 0.25rem;
}

.gray_box .mcq_hint_content {
  border-left: 2px solid #5653fe;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 2rem;
  padding: 0.5rem 0 0.5rem 1rem;
}

.gray_box .incorrect_congo {
  border-left: 2px solid;
  margin-top: 2rem;
  padding: 0.25rem 0 0.25rem 1rem;
  border-color: #d43333;
}

.gray_box .incorrect_congo_hd {
  align-items: start;
  display: flex;
  margin: 0;
}

.gray_box .incorrect_congo__title {
  flex: 1 1;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5;
  color: #24234c;
  margin: 0;
}

.gray_box .incorrect_congo__text {
  font-size: 1rem;
  line-height: 1.7;
  margin-top: 1rem;
  /* white-space: pre-line; */
}

.gray_box .incorrect_congo_hd svg {
  margin-right: 0.5rem;
  margin-top: 0.25rem;
}

.gray_box .quiz-checkmark-incorrect {
  fill: #d43333;
  color: #d43333;
}

.gray_box .quiz-checkmark-disabled {
  fill: #c4c7cc;
  color: #c4c7cc;
}

.gray_box .hint_submit_btn_wrap {
  /* display: grid;
  grid-template-columns: repeat(2, 1fr); */
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.gray_box .guide-lesson-quiz__action {
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: 600;
  height: 40px;
  line-height: 1.5;
  transition: 0.2s;
  width: 104px;
}

.gray_box .guide-lesson-quiz__hint-button {
  background-color: #f5f5f5;
  border: 1px solid #c4c7cc;
  color: #24234c;
  grid-column: 1;
  justify-self: start;
 
}

.gray_box .guide-lesson-quiz__submit-button {
  background-color: #5653fe;
  border: 1px solid #5653fe;
  color: #fff;
  grid-column: 2;
  justify-self: end;
  cursor: pointer;
}

/* end css quiz */

/* / ai_chat_bots / */
.aiChat_header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.aiChat_title {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5em;
}
.ai_chat_all {
  font-size: 12px;
  color: #5653fe;
  text-decoration: underline;
}
.ai_bot_topCard {
  margin: 13px 20px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  display: flex;
  height: 82px;
  overflow: hidden;
  padding: 8px 12px;
  transition-duration: 0.2s;
  cursor: pointer;
}
.ai_bot_topCard:active {
  transform: scale(0.98);
}
.aiBot_topCard_container {
  display: flex;
  overflow: hidden;
  flex: 1 1;
}
.ai_botCard_content {
  margin-left: 10px;
  overflow: hidden;
}
.ai_botCard_content > h2 {
  font-size: 14px;
  font-weight: 700;
  color: #24234c;
}
.ai_botCard_content > h3 {
  font-size: 10px;
  color: #40444d;
  font-weight: 400;
  line-height: 1.5em;
  margin: 5px 0;
}
.ai-bot-card_pera {
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ai_bot_list_container {
  padding: 10px 20px;
}
.ai_bot_list_container > h2 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}
.ai_nav_button {
  background: #eef;
  border: 1px solid #eef;
  border-radius: 64px;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
  transition-duration: 0.2s;
  min-width: -webkit-max-content;
  min-width: max-content;
  margin-right: 10px;
  cursor: pointer;
}
.ai_nav_button.active {
  border-color: #5653fe;
}
.ai_list_nav {
  display: flex;
  overflow-x: auto;
  padding: 10px 0;
}
.ai_bot_topCard.ai_botbody_card {
  margin: 16px 0;
}
.ai_bot_topCard.ai_botbody_card .ai-bot-card_pera {
  margin-top: 7px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  white-space: unset;
  line-height: 1.3em;
}
.containerBottom_padding {
  padding-bottom: 35px;
}
@media (max-width: 400px) {
  .ai_bot_list_container {
    padding: 10px 10px;
  }
  .ai_bot_topCard {
    margin: 13px 0;
  }
  .aiChat_header {
    margin-top: 20px;
  }
}
.header.all_chatheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 27px 11px;
  z-index: 999;
}
.header.all_chatheader h2 {
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.header.all_chatheader i {
  color: #48484a;
  font-size: 17px;
  cursor: pointer;
}
.header.all_chatheader svg {
  cursor: pointer;
}
.ai_search-form input {
  background-color: #f5f7f9;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  line-height: 150%;
  outline: none;
  padding: 10px 16px 10px 32px;
  transition: 0.2s;
  width: 100%;
}

.ai_chat_Search_header {
  padding: 10px 12px;
  position: -webkit-sticky;
  position: sticky;
  top: 59px;
  background-color: #fff;
}
.ai_search-form {
  position: relative;
}
.ai_search-form svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
}
.container_large.all_chat_list_container {
  padding-top: 5px;
  padding-bottom: 110px;
}
.ai_bot_topCard.allchat_box_item {
  margin: 12px 0;
  border-radius: 0;
  border-color: transparent;
  border-bottom-color: #e5e7eb;
  height: 85px;
}
.chat_info_header {
  display: flex;
  align-items: center;
}
.chat_info_content {
  margin-left: 15px;
}
.chat_info_content > h4 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12px;
}
.chat_tag {
  background-color: #ddf;
  border-radius: 2px;
  display: inline-flex;
  font-size: 0.625rem;
  font-weight: 600;
  line-height: 150%;
  padding: 2px 6px;
  margin-right: 7px;
}
.chat_info_cotainer {
  padding: 16px 5px;
}
.chatgpt_infoBtn {
  border: 0;
  background-color: rgb(245, 247, 249);
  padding: 8px 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 5px;
}
.chatgpt_infoBtn svg {
  margin-right: 5px;
}
.chat_info_pera {
  font-size: 14px;
  line-height: 150%;
  margin: 10px 0 10px;
}
.ai_boot_message {
  align-items: start;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  padding: 16px;
}
.chatgpt_infoBtn_container {
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 17px;
}
.chat_content {
  margin-left: 10px;
}
.ai_boot_message p {
  font-size: 14px;
  line-height: 1.5em;
  min-width: 0;
  overflow-wrap: break-word;
  white-space: pre-line;
}
.ai_boot_message.ans_maessage {
  background-color: #f5f7f9;
}

.chat_mass_container {
  display: block;
  width: 500px;
  max-width: 500px;
  border-radius: 10px 10px 0 0;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  background-color: #fff;
  padding: 10px 17px;
}
.chat_search_box {
  position: relative;
}
.chat_search_box img {
  /* position: absolute; */
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  filter: brightness(0) saturate(100%) invert(28%) sepia(99%) saturate(2068%)
    hue-rotate(232deg) brightness(91%) contrast(125%);
  display: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  z-index: 999;
}
.chat_search_box input {
  width: 100%;
  align-items: center;
  background-color: #f5f5f5;
  border: 1px solid #e5e7eb;
  border-radius: 1rem;
  display: flex;
  padding: 15px 8px 15px 16px;
  position: relative;
  transition: 0.2s;
  font-size: 17px;
}
.chat_search_box input:focus {
  border-color: #4642ff;
  outline: #4642ff;
}
.chat_search_box input::-webkit-search-cancel-button {
  display: none;
}
.chat_search_box input:not(:placeholder-shown) {
  border-color: #4642ff;
}
/* .chat_search_box input:not(:placeholder-shown) + svg {
  opacity: 1;
} */
/* .chat_search_box:has(textarea:not(:placeholder-shown)) img{
  opacity: 1;
 
} */
.chat_search_box input:not(:placeholder-shown) ~ img {
  opacity: 1;
}
.chat_search_box .dnXmp {
  left: -23px !important;
}

.chat_search_box .circle_loader {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  z-index: 999;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #cfd1d3;
  border-bottom-color: transparent;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }
  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}

.chat_search_box textarea {
  width: 100%;
  align-items: center;
  background-color: #f5f5f5;
  border: 1px solid #e5e7eb;
  border-radius: 1rem;
  display: flex;
  padding: 15px 30px 15px 16px;
  position: relative;
  transition: 0.2s;
  font-size: 17px;
  resize: none;
  z-index: 8;
}
.chat_search_box textarea:focus {
  border-color: #4642ff;
  outline: #4642ff;
}
.chat_search_box textarea::-webkit-search-cancel-button {
  display: none;
}
.chat_search_box textarea:not(:placeholder-shown) {
  border-color: #4642ff;
}
/* .chat_search_box textarea:not(:placeholder-shown) + svg {
  opacity: 1;
} */
.chat_search_box textarea:not(:placeholder-shown) ~ img {
  opacity: 1;
}
@media (max-width: 575.98px) {
  .chat_mass_container {
    max-width: 400px;
  }
}
@media (max-width: 400px) {
  .chat_mass_container {
    width: 100%;
    max-width: 100%;
  }
  .chat_search_box input {
    padding: 10px 8px 10px 16px;
    font-size: 16px;
  }
}

.chat_info_poup {
  background-color: #0009;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
.new_chatPoup_edit {
  background-color: #fff;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 29px 34px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.chatgpt_infoBtn.active {
  background-color: #5653fe;
  color: #fff;
}
.new_chatPoup_edit.new_chatPoup_edit-show {
  bottom: 0;
}
.chat_info_poup_show {
  position: absolute;
  color: #565b66;
  font-size: 20px;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.chatgpt_infoBtn.active {
  background-color: #5653fe;
  color: #fff;
}

.all_chat_poup {
  background-color: #0009;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
}

.all_chatPoup_edit {
  background-color: #fff;
  position: absolute;
  width: 100%;
  bottom: -600px;
  padding: 29px 34px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 600px;
  overflow-y: scroll;
  transition: all 0.5s ease-in-out;
}
.all_chatPoup_edit.all_chatPoup_edit-show {
  bottom: 0;
}
.explore_bots_text {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}
.ai_chat_Search_header.allChat_poup {
  padding: 10px 0px;
  position: unset;
}
.all_chatPoup_box_close {
  position: absolute;
  color: #565b66;
  font-size: 20px;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.session_delete_poup {
  background-color: #0009;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
}

.session_delete_edit {
  background-color: #fff;
  position: absolute;
  width: 100%;
  bottom: -200px;
  padding: 29px 34px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 200px;

  transition: all 0.5s ease-in-out;
}
.session_delete_edit.session_delete_edit-show {
  bottom: 0;
}
.delete_session_heading {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
}
.delete_session_pera {
  font-size: 16px;
  margin-bottom: 20px;
}

.delete_sessionbtn_box {
  display: flex;
  gap: 10px;
}
.delete_sessionbtn_box button {
  border: none;
  border-radius: 8px;
  flex: 1 1;
  font-size: 16px;
  font-weight: 600;
  padding: 13px 25px;
  transition: 0.2s;
}
.delete_session_btn {
  background-color: #5653fe;
  color: #fff;
}
.delete_session_close {
  position: absolute;
  color: #565b66;
  font-size: 20px;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
@media (max-width: 400px) {
  .delete_session_heading {
    font-size: 16px;
  }
  .delete_session_pera {
    font-size: 14px;
  }
  .session_delete_edit {
    padding: 23px 26px;
  }
}

/*//ai_chat_bots */

/* start chat loading css */
.chat_loading {
  width: 100%;
  margin-left: 10px;
}
.chat_loading--dot {
  animation: dot-keyframes 1.5s infinite ease-in-out;
  background-color: rgb(196, 197, 199);
  border-radius: 10px;
  display: inline-block;
  height: 7px;
  width: 7px;
  margin: 0 1px;
}

.chat_loading--dot:nth-child(2) {
  animation-delay: 0.5s;
}

.chat_loading--dot:nth-child(3) {
  animation-delay: 1s;
}
@keyframes dot-keyframes {
  0% {
    opacity: 0.4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
}
/* end chat loading css */

/* / email_code_poup / */
.email_codCheck_poup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #7f7f7f69;
  /* / display: none; / */
}
.email_codCheck_box {
  width: 390px;
  max-width: 390px;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 13px 22px;
  border-radius: 10px;
  text-align: center;
}
.Closeicone_box {
  text-align: right;
}
.Closeicone_box i {
  cursor: pointer;
  font-size: 22px;
}
.code_input_box input[type="number"] {
  width: 229px;
  height: 43px;
  margin: 0 5px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  border: 1px solid #dddddd;
  outline: 0;
  border-radius: 5px;
}

.code_input_box input[type="number"]::-webkit-outer-spin-button,
.code_input_box input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.code_input_box input[type="submit"] {
  margin: 20px 0 0;
  max-width: 85%;
}
.code_input_box {
  padding: 20px 0;
}
.email_codCheck_box h3 {
  font-size: 20px;
  color: #22234a;
  margin-top: 20px;
}
.email_codCheck_box p {
  font-size: 15px;
  margin: 13px 0 4px;
  color: #737376;
}
@media (max-width: 576px) {
  .email_codCheck_box {
    width: 90%;
    max-width: 90%;
  }
  .code_input_box input[type="number"] {
    max-width:100%;
    height: 30px;
    font-size: 16px;
    margin: 0 3px;
  }
  .email_codCheck_box h3 {
    font-size: 20px;
  }
  .email_codCheck_box p {
    margin: 10px 0 3px;
  }
  .Closeicone_box i {
    font-size: 18px;
  }
}
/* / email_code_poup / */

/* 28 day challenge */
.daychlng {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 27px 11px;
  z-index: 999;
}

.contday {
  font-size: 1rem;
  font-weight: 600;
  line-height: 150%;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.challenge_head {
  color: #24234c;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.4;
}
.challenges_description {
  color: #40444d;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 0.5rem;
}
.progress_barday {
  background-color: #e5e7eb;
  border-radius: 9999px;
  flex: 1 1;
  height: 0.75rem;
  overflow: hidden;
}
.progress_lineday {
  width: 7%;
  height: 100%;
  background-color: #19aa32;
}
.prdiv {
  align-items: center;
  display: flex;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 1rem;
  width: 100%;
}
.dayppercent {
  margin-left: 0.625rem;
}
.scndheadday {
  padding: 1rem 1.5rem;
  padding-top: 0;
}
.scrollstday {
  background-color: #fff;
  position: sticky;
  top: 63px;
  z-index: 1;
}
.unorderlistday {
  display: flex;
  overflow-x: auto;
  padding: 0 1rem;
  scroll-padding: 1rem;
  scroll-snap-type: x mandatory;
}
.unorderlistday li {
  list-style: none;
  scroll-snap-align: center;
}
.uldaybtn {
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  background: none;
  border: none;
  border-bottom: 1px solid #0000;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 64px;
  justify-content: center;
  outline: none;
  transition-duration: 0.2s;
  width: 48px;
}
.unorderlistday li strong {
  color: #24234c;
  margin-top: 0.5rem;
}
.challenges_divider {
  border: none;
  border-bottom: 1px solid #c4c7cc;
  margin-top: -1px;
  position: relative;
  z-index: -1;
}
.unorderlistday::-webkit-scrollbar {
  display: none;
}
.mybtnactive {
  border-color: #5653fe;
}
.daysec {
  padding-bottom: 6rem;
}
.bodydayul {
  padding: 1.5rem;
}
.taskliday {
  list-style: none;
}
.myinnerbtnday {
  align-items: center;
  background-color: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1.25rem 0.75rem 0.75rem;
  text-align: left;
  transition: 0.2s;
  width: 100%;
}

.myinnerbtnday:hover {
  cursor: pointer;
}

.innerdayspan {
  align-items: center;
  display: flex;
}
.myimgwh {
  width: 48px;
  height: 48px;
}
.newinnerday {
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
}
.dayinner_title {
  color: #24234c;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
}
.dayinner_description {
  color: #24234c;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
}
.challenges_daybtn {
  padding: 0 0 1rem;
  display: block;
  width: 500px;
  max-width: 500px;
  border-radius: 10px 10px 0 0;
  position: fixed;
  bottom: 55px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: none;
  z-index: 999;
  background-color: transparent;
}
.daymybtnch {
  background-color: #5653fe;
  border: none;
  border-radius: 0.375rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  padding: 0.75rem 1.5rem;
  transition: 0.2s;
  width: 100%;
}
.martopdm {
  margin-top: 0.5rem;
}
.myinnerbtnday.active {
  border: 1px solid #5653fe;
}
@media (max-width: 575px) {
  .challenges_daybtn {
    padding: 0 3rem 1rem;
  }
}
/* 28day challenge */

/* hi-page */

.hi_box > h2 {
  font-size: 29px;
  line-height: 1.4em;
  color: #24234c;
  padding: 20px 0 10px;
}
.hi_box > p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7em;
}

.hi_box img {
  margin: 50px 0 70px;
  max-width: 100%;
}

/* hi-page */

/* 28days_ai_challange */

.days28_ai_challenge_main .header.all_chatheader {
  border: none;
}
.days28_ai_challenge_img .container {
  padding: 0;
  padding-top: 1rem;
}
.days28_ai_challenge_main h3 {
  font-size: 24px;
}
.days28_ai_challenge_main h4 {
  font-size: 21px;
  font-weight: 700;
}
.days28_ai_challenge_main p {
  margin-top: 15px;
  font-size: 15px;
  line-height: 1.5;
}
.days28_ai_challenge_btn_div .btn {
  margin-top: 30px;
}
.days28_ai_challenge_btn_div {
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 30px;
}
.days28_ai_challenge_challenge_inner_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.days28_ai_challenge_challenge_div {
  padding: 30px 0;
}
.days28_ai_challenge_challenge_inner_sub {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 10px;
  width: 49%;
}
.days28_ai_challenge_light_text {
  font-weight: 300;
  font-size: 13px;
  margin-top: 0px !important;
}
.days28_ai_challenge_challenge_inner_sub h5 {
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;
}
.days28_ai_challenge_challenge_inner_sub h5 i {
  color: #4642ff;
  margin-right: 10px;
}
.days28_ai_challenge_challenge_inner_sub h5 img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.days28_ai_challenge_stories {
  margin-top: 30px;
}

.days28_ai_challenge_main .stories_name_header h4 {
  font-size: 16px;
}
.days28_ai_challenge_main .stories_name_header p {
  font-size: 13px;
  margin-top: 0px;
}

@media screen and (max-width: 450px) {
  .days28_ai_challenge_img .container {
    padding: 1rem;
  }
}
/* 28days_ai_challange */
/* ************** challenges start *********** */
.challenges-header {
  border-bottom: 0;
}
.tablink {
  background-color: transparent;
  float: left;
  border: none;
  border-bottom: 1px solid #e5e7eb;
  outline: none;
  cursor: pointer;
  padding: 14px;
  font-size: 17px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  width: 50%;
  color: #6b7280;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5;
  transition: 0.2s;
}
.tablink:active {
  background-color: #eeeeff6e;
  color: #24234c;
  border-bottom: 1px solid #5653fe;
}

.tabcontent {
  color: white;
  padding: 80px 0;
  height: 100%;
}

#Home {
  background-color: transparent;
}
#News {
  background-color: transparent;
}

.first-challenge-box {
  align-items: center;
  background-color: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  display: flex;
  overflow: hidden;
  padding: 0.75rem;
  text-align: left;
  transition: 0.2s;
  width: 100%;
  margin: 0 0 35px;
}
.first-challenge-box:active {
  background-color: #eeeeff6e;
  transform: scale(0.99);
}
.first-challenge-box .img-box {
  width: 20%;
}
.first-challenge-box .textbox {
  width: 80%;
  margin-left: 10px;
}
.challenge-progress {
  width: 46%;
}
.textbox h5 {
  color: #24234c;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.textbox p {
  color: #6b7280;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.3;
  margin-top: 0.5rem;
}
.challenge-progress_bar {
  margin: 7px 0;
}
.second-challenge-box {
  background-color: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  max-width: 100%;
  padding: 0.5rem 0.5rem 1rem;
  width: 100%;
}
.second-challenge-box h4 {
  color: #24234c;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  padding: 1rem 0.5rem 0;
}
.second-challenge-box p {
  padding: 0 0.2rem 0;
}
.second-challenge-box p strong {
  color: #6b7280;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.3;
  padding: 0 3px;
}
.tab2-box {
  text-align: center;
}
.tab2-box h3 {
  color: #24234c;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.4;
  margin-top: 2rem;
}
.tab2-box p {
  color: #24234c;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 0.5rem;
}
.tab2-box {
  margin-top: 30px;
}
/* ************** challenges end*********** */
@media (max-width: 575.98px) {
  .new_chatPoup_edit {
    padding: 29px 34px 80px;
  }
  .session_delete_edit{
    padding: 29px 34px 80px;
    height: 250px;
  }

}

/* / task_tomorrow_poup // */
.task_tomorrow_poup{
    /* position: fixed; */
    /* top: 10px;
    left: 15px;
    right: 15px; */
    background-color: #40444d;
    z-index: 999;
    color: #fff;
    padding: 12px 16px;
    border-radius: 10px;
    overflow: hidden;
    margin: 13px;
   
}
.task_tomorrow_textBox{
    margin-left: 10px;
}
.task_tomorrow_textBox > h3{
    font-size: 16px;
    padding-bottom: 6px;
}
.task_tomorrow_textBox > p{
    font-size: 15px;
    margin-top: 5px;
    /* text-decoration: underline; */
}
.task_tomorrow_poup-row{
    display: flex;
    justify-content: space-between;
    font-size: 21px;
}
.poup_close_box{
    margin-left: 10px;
}
.poup_close_box i{
    margin-top: 4px;
    cursor: pointer;
}
@media screen and (max-width: 767px) {
    .task_tomorrow_textBox > h3{
        font-size: 15px;
        padding-bottom: 6px;
    }
    .task_tomorrow_textBox > p{
        font-size: 14px;
    }
}
@media screen and (max-width: 576px) {
    .task_tomorrow_poup-row{
        justify-content: space-between;
        align-items: center;
    }
    .task_tomorrow_textBox{
        text-align: center;
    }
}

/* / task_tomorrow_poup / */

/* chat response box */
.ai_boats .great-box-main{
    position: absolute;
    bottom: 0;
    left: 0;
    /* border-top: 2px solid #19aa32; */
    padding: 20px 0;
    width: 100%;
}
.ai_boats .great-box-main .p-container{
  padding: 23px;
}
.ai_boats .great-box{
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.ai_boats .great-img{
    margin-right: 10px;
}
.ai_boats .great-img svg{
    vertical-align: middle;
}
.ai_boats .great-text p{
    font-size: 20px;
    font-weight: 700;
    color: #343434;
    font-family: "Inter", sans-serif;
    margin: 0;
}
.ai_boats .great-btn{
    display: block;
    width: 100%;
    padding: 15px 30px;
    border-radius: 7px;
    /* background: #19aa32; */
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    margin-top: 10px;
    cursor: pointer;
}

/* chat response box */

/* 404 ! page not found start*/
.pageNot_found_container {
  max-width: 500px;
  height: 100vh;
  margin: 0 auto;
  padding: 34px 24px;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.pageNot_found_heading {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2em;
  margin-bottom: 14px;
  text-align: center;
}

.pageNot_found_pera {
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 400;
  color: rgb(138, 138, 142);
  text-align: center;
}

.not_found_img {
  max-width: 100%;
}

/* 404 ! page not found end*/
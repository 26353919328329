
/* ***fixe_study_map*** */
/* .footer_nav .img-box img {
  opacity: 1;
} */
.new_chapter_box .chapter_inner .chapter_mapBox.item_5 li {
  width: 48px;
  height: 48px;
}
.new_chapter_box
  .chapter_inner
  .chapter_mapBox.item_5
  li:not(:first-child)::after {
  left: calc(-100% + 8px);
  width: 35px;
}
.new_chapter_box .chapter_inner .chapter_mapBox.item_6 li {
  width: 45px;
  height: 45px;
}

.new_chapter_box
  .chapter_inner
  .chapter_mapBox.item_6
  li:not(:first-child)::after {
  left: unset;
  right: calc(100% + 2px);
  width: 24px;
}

.course_sliderContainer .course_box .course_content_box {
  min-height: 64px;
}
.course_box .course_content_box p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.books_sliderContainer .course_box .course_content_box p {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 500px) {
  .new_chapter_box .chapter_inner .chapter_mapBox.item_5 li {
    width: 42px;
    height: 42px;
  }
  .new_chapter_box
    .chapter_inner
    .chapter_mapBox.item_5
    li:not(:first-child)::after {
    left: unset;
    width: 20px;
    border-width: 1px;
    right: calc(100% + 5px);
  }
  .new_chapter_box
    .chapter_inner
    .chapter_mapBox.item_6
    li:not(:first-child)::after {
    left: unset;
    right: calc(100% + 1px);
    width: 20px;
    border-width: 1px;
  }
}
@media screen and (max-width: 412px) {
  .new_chapter_box .chapter_inner .chapter_mapBox li:not(:first-child)::after {
    left: calc(-100% + 1px);
    width: 41px;
  }
  .new_chapter_box
    .chapter_inner
    .chapter_mapBox.item_6
    li:not(:first-child)::after {
    width: 9px;
  }
}

@media screen and (max-width: 360px) {
  .new_chapter_box .chapter_inner .chapter_mapBox li:not(:first-child)::after {
    left: calc(-100% + 11px);
    width: 31px;
  }
  .new_chapter_box
    .chapter_inner
    .chapter_mapBox.item_5
    li:not(:first-child)::after {
    left: unset;
    width: 16px;
    border-width: 1px;
    right: calc(100% + 3px);
  }
  .new_chapter_box .chapter_inner .chapter_mapBox.item_6 li {
    width: 40px;
    height: 40px;
  }
  .new_chapter_box .chapter_inner .chapter_mapBox li.active {
    box-shadow: 0 0 0 6px #ded7f7;
  }
}

@media screen and (max-width: 320px) {
  .new_chapter_box .chapter_inner .chapter_mapBox li:not(:first-child)::after {
    left: calc(-100% + 23px);
    width: 21px;
  }
  .new_chapter_box
    .chapter_inner
    .chapter_mapBox.item_5
    li:not(:first-child)::after {
    left: unset;
    width: 10px;
    border-width: 1px;
    right: calc(100% + 0px);
  }
  .new_chapter_box .chapter_inner .chapter_mapBox.item_6 li {
    width: 35px;
    height: 35px;
  }
  .new_chapter_box
    .chapter_inner
    .chapter_mapBox.item_6
    li:not(:first-child)::after {
    display: none;
  }
}
/* / lesson_7_to_10 / */

.new_chapter_box .chapter_inner .chapter_mapBox.item_7 li {
  width: 40px;
  height: 40px;
}
.new_chapter_box
  .chapter_inner
  .chapter_mapBox.item_7
  li:not(:first-child)::after {
  left: calc(-100% + 21px);
  width: 17px;
}

.new_chapter_box .chapter_inner .chapter_mapBox.item_8 li {
  width: 40px;
  height: 40px;
}
.new_chapter_box
  .chapter_inner
  .chapter_mapBox.item_8
  li:not(:first-child)::after {
  left: calc(-100% + 29px);
  width: 9px;
}
.new_chapter_box .chapter_inner .chapter_mapBox.item_9 li {
  width: 40px;
  height: 40px;
}
.new_chapter_box
  .chapter_inner
  .chapter_mapBox.item_9
  li:not(:first-child)::after {
  display: none;
}
.new_chapter_box .chapter_inner .chapter_mapBox.item_9 li.active {
  box-shadow: 0 0 0 6px #ded7f7;
}
@media screen and (max-width: 500px) {
  .new_chapter_box .chapter_inner .chapter_mapBox.item_7 li {
    width: 42px;
    height: 42px;
  }
  .new_chapter_box
    .chapter_inner
    .chapter_mapBox.item_7
    li:not(:first-child)::after {
    left: unset;
    width: 18px;
    border-width: 1px;
    right: calc(100% + 1px);
  }
  .new_chapter_box
    .chapter_inner
    .chapter_mapBox.item_8
    li:not(:first-child)::after {
    display: none;
  }
}

@media screen and (max-width: 360px) {
  .new_chapter_box .chapter_inner .chapter_mapBox.item_7 li {
    width: 36px;
    height: 36px;
  }
  .new_chapter_box
    .chapter_inner
    .chapter_mapBox.item_7
    li:not(:first-child)::after {
    display: none;
  }
  .new_chapter_box .chapter_inner .chapter_mapBox.item_8 li {
    width: 30px;
    height: 30px;
  }
  .new_chapter_box .chapter_inner .chapter_mapBox.item_8 li img {
    max-width: 15px;
  }
  .new_chapter_box .chapter_inner .chapter_mapBox.item_9 li {
    width: 30px;
    height: 30px;
  }
  .new_chapter_box .chapter_inner .chapter_mapBox.item_9 li img {
    max-width: 15px;
  }
}

@media screen and (max-width: 320px) {
  .new_chapter_box .chapter_inner .chapter_mapBox.item_7 li {
    width: 32px;
    height: 32px;
  }
  .new_chapter_box .chapter_inner .chapter_mapBox.item_7 li.active {
    box-shadow: none;
  }
  .new_chapter_box .chapter_inner .chapter_mapBox.item_9 li {
    width: 25px;
    height: 25px;
  }
  .new_chapter_box .chapter_inner .chapter_mapBox.item_9 li img {
    max-width: 12px;
  }
}

/* / lesson_7_to_10 / */
/* ***fixe_study_map*** */

/* *******new_Ui_type_css********* */
.book_complete {
    color: #4c4b98;
  }
  .courses_box .content_box .progress-bar .line {
    background-color: #f98c22;
  }
  .courses_box .content_box .progress-bar {
    background-color: #f2e1cd;
  }
  .read_more_btn button {
    background-color: #795af6;
    font-family: "Albert Sans", sans-serif;
  }
  .complete_div {
    background-color: #fff4e7;
  }
  .stock_tabBox ul {
    border-color: #f2e1cd;
  }
  .stock_tabBox ul li.active {
    border-color: #f98c22;
  }
  .top_header {
    padding: 13px 24px;
  }
  .tag.tag_beginner,
  .tag.tag_intermediate {
    color: #f98c22;
    background-color: #fff4e9;
    border-color: #f98c22;
  }
  .courses_box .tag.tag_beginner {
    color: #0f661e;
    background-color: #f2f9ed;
    border-color: #7abf4c;
  }
  .challenges_box .progress-bar .line {
    background-color: #795af4;
  }
  .btn_theme_1 {
    background-color: #795af6;
    font-family: "Albert Sans", sans-serif;
  }
  .bars_track .bars_line {
    background-color: #795af4;
  }
  .read_chapter_guideBox .left_side {
    border-color: #795af4;
  }
  .finish_lesson_btn {
    background-color: #795af4;
  }
  .courses_titleBar span {
    color: #795af4;
  }
  .read_chapter_guideBox .right_side > div img {
    filter: brightness(0) saturate(100%) invert(33%) sepia(84%) saturate(3919%)
      hue-rotate(240deg) brightness(107%) contrast(91%);
  }
  .announceList_box {
    transition: all 0.5s ease-in-out;
  }
  .announceList_box:hover {
    background-color: rgb(238, 238, 255);
  }
  .per_day_select.day_selected {
    border-color: #795af4;
  }
  .leaderboard_header {
    background-color: #4c4b98;
  }
  .announce_scoreBox {
    background-color: #3d3c95;
    border-color: #3d3c95;
  }
  .promotion_zone {
    background-color: #12ccf430;
  }
  .promotion_zone p {
    color: #078ba7;
  }
  .promotion_zone div.line {
    background-color: #078ba7;
  }
  .demotion_zone {
    background-color: #fff4e7;
  }
  .demotion_zone p {
    color: #f98c22;
  }
  .demotion_zone div.line {
    background-color: #f98c22;
  }
  .announceList_box {
    border-bottom: 1px solid #eeee;
  }
  .profile_header {
    background-color: #4c4b98;
  }
  .milestones_conetntBox .progress-bar {
    background-color: #f2e1cd;
  }
  .milestones_conetntBox .progress-bar .line {
    background-color: #f98c22;
  }
  .claim_badge {
    background-color: #795af6;
    font-family: "Albert Sans", sans-serif;
  }
  .account_value_box {
    background: #3a399a;
  }
  .metrics_box > div {
    background-color: rgb(32 30 139);
    border-color: #151285;
  }
  .demo_tag {
    background-color: #795af6;
  }
  .asset_container li {
    border-bottom: 1px solid #eeee;
    padding: 15px 0;
    margin-bottom: 0;
  }
  /* *******new_Ui_type_css********* */
  

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 13px;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }

  /* Skeleton CSS*/

.skeleton .wrapper {
  /* margin: 30px;
  padding: 30px; */
  background: #fff;
  /* / width: 360px; /
  / height: 640px; /
  / display: flex; /
  / flex-direction: column; / */
  position: relative;
}

.skeleton .wrapper-cell {
   /* / display: flex; / */
   padding-bottom: 80px;
   height: 30px;
}


@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}




.skeleton .image {
  height: 60px;
  width: 60px;
  border-radius: 100px;
  animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #ccc;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 800px 104px;
    /* / height: 96px; / */
    position: relative;
}

.skeleton .text {
  margin-left: 80px;
  position: relative;
  top: -60px;
}

.skeleton .text-line {
  height: 60px;
  /* / width: 230px; / */
  margin: 4px 0;
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #ccc;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 800px 104px;
  /* / height: 96px; / */
  position: relative;
}


/* / stock_order_place / */
.stock_order_popup {
  position: fixed;
  left: 50%;
  top: -70px;
  transform: translateX(-50%);
  width: 100%;
  max-width: 500px;
  z-index: 999;
  background-color: #ffffff;
  padding: 11px 14px;
  border-radius: 5px;
  box-shadow: 0 0 8px 0px #eeeeee;
  transition: all 0.5s ease-in-out;
}
.stock_order_popup.active {
  top: 10px;
}
.processing_box,
.conform_box {
  display: flex;
  align-items: center;
}
.conform_box .icon_box {
  width: 20px;
  height: 20px;
  background-color: #19aa32;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
}
.conform_box .icon_box {
  margin-right: 7px;
}
.processing_box .processing_animation {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border-top: 1px solid #f98c22;
  border-left: 1px solid #f39d47;
  animation: processing 0.5s linear infinite;
}
@keyframes processing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.processing_box p {
  margin-left: 7px;
  font-size: 15px;
}
.conform_box p {
  font-size: 15px;
}
.submit_form span.error_text{
  color: red;
}
/* / stock_order_place / */
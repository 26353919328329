* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
ul,
li,
a,
label {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}

a:hover {
  color: inherit;
}
li {
  list-style: none;
}

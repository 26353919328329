.main_container {
  max-width: 500px;
  margin: 0 auto;
}
.img_fluid {
  display: block;
  max-width: 100%;
}
/* top_header */

/* top_header */
.top_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #ffffff;
}

.top_header .logo_box {
  max-width: 133px;
}
.top_header .coin_store_list {
  display: flex;
}
.top_header .coin_store_list li p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgb(72, 72, 74);
  padding-left: 2px;
}
/* top_header */
/* top_nav_box */
.top_nav_box {
  padding: 10px 24px;
  border-bottom: 1px solid #e5e7eb;
  position: sticky;
  top: 72px;
  z-index: 999;
  background-color: #ffffff;
}
.top_nav_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: scroll;
}
/* width */
.top_nav_list::-webkit-scrollbar {
  height: 3px;
}

/* Track */
.top_nav_list::-webkit-scrollbar-track {
  background: #ffffff55;
}

/* Handle */
.top_nav_list::-webkit-scrollbar-thumb {
  background: #5653fe7a;
}

.top_nav_list li {
  padding: 5px 20px;
  font-weight: 500;
  line-height: 24px;
  color: #000000;
  cursor: pointer;
  margin-bottom: 5px;
}
.top_nav_list li .active {
  padding: 5px 20px;
  background-color: #eeeeff;
  border-radius: 99px;
  border: 1px solid #5653fe;
}
.top_nav_list li a.active {
  padding: 5px 20px;
  background-color: #eeeeff;
  border-radius: 99px;
  border: 1px solid #5653fe;
}
/* top_nav_box */
/* chapter_box */
.chapter_box {
  min-height: 100vh;
  background-color: #f5f7f9;
  padding: 24px 24px 100px;
  position: relative;
}
.chapter_fixed_box {
  display: flex;
  background-color: #5653fe;
  color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 0 0 #4542d5;
  justify-content: space-between;
  position: sticky;
  top: 150px;
  z-index: 999;
}
.chapter_fixed_box .left_content {
  padding: 12px 16px;
}
.chapter_fixed_box .right_box {
  padding: 0 20px;
  border-left: 1px solid #4542d5;
  display: flex;
  align-items: center;
}
.chapter_fixed_box .left_content .title {
  font-size: 18px;
  color: #ffffff;
  line-height: 27px;
  font-weight: 600;
  margin-bottom: 4px;
}
.chapter_fixed_box .left_content .sub_title {
  font-size: 14px;
  font-weight: 600;
  color: #bbbaff;
}
.chapter_title_box {
  display: flex;
  align-items: center;
}
.chapter_title_box .chapter_title_content p {
  color: #a6aab3;
  font-style: italic;
  margin-bottom: 3px;
}
.chapter_title_box .chapter_title_content h5 {
  font-size: 16px;
  color: #6b7280;
  font-style: italic;
  font-weight: 600;
}
.main_chapter_box {
  margin: 32px 0;
}
.chapter_title_box .line {
  height: 2px;
  background-color: #c4c7cc;
  flex-grow: 1;
  margin-left: 15px;
}
.step-container {
  display: flex;
  justify-content: center;
  padding: 40px 0 0;
}
.step_inner {
  position: relative;
}
.step_inner .step_box {
  margin-top: 32px;
  position: relative;
}
.step_inner .step_box .step_line_box {
  position: relative;
}
.step_inner .step_box .step_line_box .line {
  position: absolute;
  top: 2px;
  left: 0;
  width: 3px;
  height: 16px;
  background-color: #898c95;
  opacity: 0.6;
  rotate: 35deg;
}
.step_inner .step_box:nth-child(2) {
  /* transform: translateX(-60px); */
  left: -60px;
}
.step_inner .step_box:nth-child(2) .step_line_box .line {
  left: unset;
  right: 0;
  rotate: -35deg;
}
.step_inner .step_box:nth-child(3) .step_line_box .line {
  left: unset;
  right: 0;
  rotate: -35deg;
}
.step_inner .step_box:nth-child(4) {
  /* transform: translateX(60px); */
  left: 60px;
}
.step_img_box {
  position: relative;
  cursor: pointer;
}
.step_inner .step_box .start_tooltip {
  display: none;
}
.step_inner .step_box.start_step_box .start_tooltip {
  display: block;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #ffffff;
  padding: 6px 14px;
  border: 1px solid #c4c7cc;
  border-radius: 10px;
  animation: bounce 2s infinite ease-in-out;
}

@keyframes bounce {
  0%,
  100% {
    transform: translate(-50%, 0);
  }
  50% {
    transform: translate(-50%, -10px);
  }
}

.step_inner .step_box.start_step_box .start_tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #ffffff;
}
.step_inner .step_box.start_step_box .start_tooltip p {
  font-size: 15px;
  font-weight: 600;
  color: #5653fe;
}
.step_inner .step_box:last-child .step_line_box {
  display: none;
}
.step_inner .step_box:nth-child(5) {
  /* transform: translate(-12px, -11px); */
  top: -11px;
  left: -12px;
}
.step_inner .step_box:nth-child(6) {
  /* transform: translate(-76px, -21px); */
  top: -21px;
  left: -76px;
}

.step-container.step-container-right
  .step_box:nth-child(1)
  .step_line_box
  .line {
  left: unset;
  right: 0;
  rotate: -35deg;
}
.step-container.step-container-right .step_box:nth-child(2) {
  /* transform: translateX(60px); */
  left: 60px;
}
.step-container.step-container-right
  .step_box:nth-child(2)
  .step_line_box
  .line {
  position: absolute;
  top: 2px;
  left: 0;
  width: 3px;
  height: 16px;
  background-color: #898c95;
  opacity: 0.6;
  rotate: 35deg;
}
.step-container.step-container-right
  .step_box:nth-child(3)
  .step_line_box
  .line {
  rotate: 35deg;
  right: unset;
  left: 0;
}
.step-container.step-container-right .step_box:nth-child(4) {
  /* transform: translateX(-60px); */
  left: -60px;
}
.step-container.step-container-right
  .step_box:nth-child(4)
  .step_line_box
  .line {
  left: unset;
  right: 0;
  rotate: -35deg;
}
.step-container.step-container-right .step_inner .step_box:nth-child(5) {
  /* transform: translate(-1px, -7px); */
  top: -1px;
  left: -7px;
}
.step-container.step-container-right .step_box:nth-child(6) {
  /* transform: translate(55px, -16px); */
  top: -16px;
  left: 55px;
}
.step-container.step-container-right .step_inner .step_box:nth-child(5) .line {
  left: unset;
  right: 0;
  rotate: -35deg;
}
.next_chapter_box {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.03);
  padding: 20px;
}
.next_chapter_box > p {
  font-size: 16px;
  color: #6b7280;
  font-weight: 600;
  margin-bottom: 10px;
}
.next_chapter_box > h5 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}
.next_chapter_box > ul {
  display: flex;
  flex-wrap: wrap;
}
.next_chapter_box > ul li {
  background-color: #eef;
  color: #5653fe;
  font-weight: 600;
  padding: 4px 8px;
  margin: 4px;
  border-radius: 2px;
}
.btn_theme_1 {
  font-size: 16px;
  background-color: rgb(86, 83, 254);
  color: rgb(255, 255, 255);
  border-radius: 8px;
  font-weight: 600;
  padding: 12px;
  border: 0;
  cursor: pointer;
}
.btn_theme_2 {
  font-size: 16px;
  color: #000000;
  background-color: #ffffff;
  border-radius: 8px;
  font-weight: 600;
  padding: 12px;
  border: 0;
  cursor: pointer;
  border: 1px solid #c4c7cc;
}
.btn_group button {
  width: 48.5%;
}
.btn_group {
  display: flex;
  justify-content: space-between;
  padding-top: 19px;
}
.chapter_popup {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.03);
  padding: 20px;
  width: 328px;
  display: none;
}
.chapter_popup::after {
  content: "";
  position: absolute;
  top: -13px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 13px solid #ffffff;
}
.chapter_popup::before {
  content: "";
  position: absolute;
  top: -14px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 13px solid #d9d9d9;
}
.step_box.not_start .chapter_popup {
  background-color: #e3e6f2;
}
.step_box.not_start .chapter_popup h5 {
  color: #6c748c;
}
.step_box.not_start .chapter_popup button {
  background-color: #d0d5e5;
  color: #6c748c;
}

.step_box.not_start .chapter_popup::before {
  border-bottom-color: #e3e6f2;
}

.step_box.not_start .chapter_popup::after {
  border-bottom-color: #e3e6f2;
}

.chapter_popup h5 {
  font-size: 18px;
  font-weight: 600;
  color: #24234c;
  line-height: 24px;
  margin-bottom: 10px;
}
.chapter_popup ul {
  display: flex;
  margin-bottom: 10px;
}
.chapter_popup ul li {
  font-size: 14px;
  opacity: 0.8;
  font-weight: 600;
  color: #6b7280;
  margin-right: 5px;
}
.chapter_popup button {
  width: 100%;
  background-color: rgb(86, 83, 254);
  color: rgb(255, 255, 255);
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  padding: 12px;
  border: none;
}
/* chapter_box */

/* footer */
.footer_nav {
  width: 500px;
  max-width: 500px;
  border-radius: 10px 10px 0px 0px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.05) 0px -2px 4px;
  margin: 0px auto;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  padding: 12px 35px;
}

.footer_nav .img-box img {
  filter: grayscale(1);
  opacity: 1;
}
.footer_nav ul {
  display: flex;
  justify-content: space-between;
}
.footer_nav ul li a .content_box p {
  font-weight: 600;
}
.footer_nav ul li a.active .content_box p {
  color: #795af6;
}
.footer_nav ul li a.active .img-box img {
  filter: brightness(0) saturate(100%) invert(35%) sepia(90%) saturate(3601%)
    hue-rotate(238deg) brightness(103%) contrast(93%);
}
.footer_nav ul li a {
  text-align: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  cursor: pointer;
}
.footer_nav ul li a {
  color: rgb(45 45 47);
}
.footer_nav ul li .content_box p {
  margin-top: 2px;
}
/* .footer_nav ul li a .active {
  color: rgb(86, 83, 254);
 
} */

/* read_chapter */
.read_chapter_container {
  max-width: 500px;
  height: 100vh;
  margin: 0 auto;
  background-color: rgb(245, 247, 249);
  padding: 18px 24px 24px;
  position: relative;
}
.read_chapter_container .close {
  margin-right: 5px;
}
.read_chapter_topBar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.bars_track {
  width: 100%;
  border-radius: 19px;
  height: 12px;
  background-color: rgb(229, 231, 235);
  position: relative;
}
.bars_track .bars_line {
  width: 5%;
  border-radius: 19px;
  background-color: rgb(25, 170, 50);
  height: 12px;
  transition: width 250ms linear;
  position: absolute;
  left: 0px;
  top: 0px;
}
.read_chapter_inner {
  height: calc(100% - 38px);
  position: relative;
}
.read_chapter_guideBox {
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 16px;
  border-radius: 8px;
  display: flex;
  padding: 40px 24px;
}
.read_chapter_guideBox > div {
  width: 100%;
  display: flex;
  align-items: center;
}
.read_chapter_guideBox .left_side {
  padding-right: 20px;
  border-right: 2px solid rgb(86, 83, 254);
  justify-content: right;
}
.read_chapter_guideBox .left_side p {
  text-align: center;
}
.read_chapter_guideBox .right_side {
  padding-left: 20px;
  text-align: center;
}
.read_chapter_guideBox .right_side > div {
  margin-top: -85px;
}
.read_chapter_guideBox .right_side > div img {
  transform: scale(1);
  animation: tap_img 2s ease-in-out 1;
}
@keyframes tap_img {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
.read_chapter_guideBox .right_side p {
  margin-top: 20px;
}
.read_chapter_contentBox {
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 16px;
  border-radius: 8px;
  padding: 40px 24px 20px;
  overflow-y: scroll;
  position: relative;
}
/* width */
.read_chapter_contentBox::-webkit-scrollbar {
  width: 3px;
}
/* Track */
.read_chapter_contentBox::-webkit-scrollbar-track {
  background: #ffffff55;
}

/* Handle */
.read_chapter_contentBox::-webkit-scrollbar-thumb {
  background: rgb(229, 231, 235);
}

.read_chapter_contentBox .read_chapter_item {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.finish_lesson_btn {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  background-color: rgb(86, 83, 254);
  color: rgb(255, 255, 255);
  font-weight: 600;
  cursor: pointer;
}
.img_box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.read_chapter_contentBox .img_box img {
  max-width: 100%;
}
.read_chapter_item > .content_box {
  padding: 31px 0;
  position: relative;
}
.read_chapter_item > div::before {
  /* content: ""; */
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 180px;
  height: 2px;
  background-color: rgb(245, 245, 245);
}
.read_chapter_contentBox .content_box .title {
  font-size: 24px;
  font-weight: 700;
  color: rgb(36, 35, 76);
  line-height: 1.5em;
}
.read_chapter_contentBox .content_box .sub_title {
  font-size: 16px;
  font-weight: 400;
  color: rgb(36, 35, 76);
  margin-top: 8px;
  line-height: 1.5em;
}
.text {
  font-size: 18px;
  font-weight: 400;
  color: rgb(36, 35, 76);
  line-height: 1.5em;
}
.selectBox_container label {
  position: relative;
}
.selectBox_container label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.selectBox_container label {
  width: 100%;
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid rgb(255, 255, 255);
  background: rgb(245, 247, 249);
  margin-bottom: 10px;
  cursor: pointer;
}
.selectBox_container label.correct {
  border: 1px solid rgb(25, 170, 50);
}
.check_box {
}
.selectBox_container label.correct .check_box {
  background-image: url(../images/correct.svg);
  background-repeat: no-repeat;
  background-size: 12px 12px;
  background-position: center center;
  border: 0;
  background-color: rgb(25, 170, 50);
}
.selectBox_container label.incorrect {
  border: 1px solid #d43333;
}
.selectBox_container label.incorrect .check_box {
  background-image: url(../images/incorrect.svg);
  background-repeat: no-repeat;
  background-size: 12px 12px;
  background-position: center center;
  border: 0;
  background-color: #d43333;
}
.selectBox_container label p {
  font-size: 16px;
  color: #24234c;
  text-transform: capitalize;
  font-weight: 500;
}
.selectBox_container {
  margin-top: 10px;
}
.selectBox_container .check_box {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid #c5c8cd;
  flex-shrink: 0;
}
/* read_chapter */
/* down_popup */
.correct_popup {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  border-top: 2px solid rgb(25, 170, 50);
  background-color: #ffffff;
  padding: 8px 9px;
  display: flex;
}
.correct_popup .img_box {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgb(25, 170, 50);
  display: flex;
  justify-content: center;
  align-items: center;
}
.correct_popup .img_box img {
  width: 90%;
  height: 90%;
}
.correct_popup .content_box p {
  font-size: 16px;
  font-weight: 600;
  margin-left: 8px;
}

.incorrect_popup {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  border-top: 2px solid #d43333;
  background-color: #ffffff;
  padding: 8px 9px;
  display: flex;
}
.incorrect_popup .img_box {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #d43333;
  display: flex;
  justify-content: center;
  align-items: center;
}
.incorrect_popup .img_box img {
  width: 90%;
  height: 90%;
}
.incorrect_popup .content_box p {
  font-size: 16px;
  font-weight: 600;
  margin-left: 8px;
}

/* down_popup */

/* finish_lesson */
.finish_lesson_header {
  display: flex;
  justify-content: space-between;
}
.coin_store ul {
  display: flex;
  align-items: center;
}
.coin_store ul li {
  margin-left: 6px;
}
.coin_store ul li:last-child {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: rgb(72, 72, 74);
}
.finish_lesson_content {
  text-align: center;
}
.continue_btnBox button {
  width: 100%;
}
.finish_lesson_inner {
  height: calc(100% - 28px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.continue_btnBox {
  width: calc(100% - 48px);
  position: absolute;
  bottom: 20px;
}
.finish_lesson_content h2 {
  font-size: 24px;
  font-weight: 700;
  color: rgb(36, 35, 76);
  margin: 23px 0px 11px;
}
.finish_lesson_content p {
  font-size: 16px;
  font-weight: 400;
  color: rgb(36, 35, 76);
}
/* finish_lesson */

/* courses_page */
.courses_nav {
  padding: 10px 24px;
  border-bottom: 1px solid #e5e7eb;
  position: sticky;
  top: 72px;
  z-index: 99;
  background-color: #ffffff;
}
.courses_nav ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: scroll;
  padding: 13px 0px;
}
/* width */
.courses_nav ul::-webkit-scrollbar {
  height: 3px;
}

/* Track */
.courses_nav ul::-webkit-scrollbar-track {
  background: #ffffff55;
}

/* Handle */
.courses_nav ul::-webkit-scrollbar-thumb {
  background: #f2e1cd;
}
.courses_nav ul li {
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  padding: 4px 16px;
  background-color: #f9fafb;
  border-radius: 50px;
  border: 1px solid #a6aab3;
  margin-right: 16px;
  cursor: pointer;
}
.courses_nav ul li.active {
  border-color: #f2e1cd;
  background-color: #f98c22;
  color: #ffffff;
}
.courses_titleBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0 16px;
}
.courses_titleBar p {
  font-size: 18px;
  font-weight: 600;
  color: #24234c;
}
.courses_titleBar span {
  font-size: 12px;
  font-weight: 600;
  color: #5653fe;
  text-transform: uppercase;
}
.courses_innerBox {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  overflow: hidden;
}
.courses_innerBox .courses_box {
  display: flex;
  background-color: #ffffff;
  padding: 12px;
  cursor: pointer;
}
.courses_innerBox .courses_box:not(:first-child) {
  border-top: 4px solid #f5f7f9;
}
.courses_innerBox .courses_box .image_side {
  padding-right: 15px;
}
.courses_innerBox .courses_box .image_side .img_box {
  width: 78px;
  height: 56px;
  border-radius: 4px;
  overflow: hidden;
}
.courses_innerBox .courses_box .image_side .img_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.courses_innerBox .courses_box .content_box {
  flex-grow: 1;
}
.tag {
  width: 100%;
  padding: 6px 7px;
  font-size: 10px;
  font-weight: 600;
  margin-top: 4px;

  text-align: center;
  border-radius: 8px;
  line-height: 1.5em;
  border: 1px solid;
}
.tag.tag_beginner {
  color: #0f661e;
  background-color: #f2f9ed;
  border-color: #7abf4c;
}
.tag.tag_intermediate {
  color: #663d00;
  background-color: #fff5e6;
  border-color: #f90;
}
.tag.tag_advanced {
  color: #000002;
  background-color: rgb(238 238 255);
  border-color: rgb(86 83 254);
}

.courses_box .content_box h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #24234c;
  margin-bottom: 5px;
  text-overflow: ellipsis;
}
.courses_box .content_box ul {
  display: flex;
  align-items: center;
}
.courses_box .content_box ul li {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-right: 6px;
  color: #565b66;
}
.courses_box .content_box .progress-bar {
  width: 100%;
  border-radius: 19px;
  position: relative;
  margin-top: 18px;
  height: 12px;
  background-color: rgb(229, 231, 235);
}
.courses_box .content_box .progress-bar .line {
  width: 21%;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: rgb(52, 199, 89);
  height: 100%;
  transition: width 250ms linear;
  border-radius: 19px;
}
/* courses_page */

/* challenges_page */
.challenges_box .progress-bar {
  width: 100%;
  border-radius: 19px;
  height: 12px;
  background-color: rgb(229, 231, 235);
  position: relative;
}
.challenges_box .progress-bar .line {
  width: 21%;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: rgb(52, 199, 89);
  height: 100%;
  transition: width 250ms linear;
  border-radius: 19px;
}
.challenges_box {
  background-color: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  padding: 16px;
}
.challenges_box > ul {
  display: flex;
  align-items: center;
  padding: 13px 0;
}
.challenges_box > ul li {
  margin-right: 10px;
}
.challenges_box > ul li:first-child {
  width: 78px;
}
.challenges_box .image_box {
  margin: 20px 0;
}
.challenges_box .image_box img {
  max-width: 100%;
}
.challenges_box .reward_box {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #6b7280;
  padding: 8px;
}
.reward_box p {
  font-size: 12px;
  font-weight: 500;
  color: #565b66;
  margin-bottom: 10px;
}
.reward_box ul {
  display: flex;
  align-items: center;
}
.reward_box ul li {
  font-size: 16px;
  color: #40444d;
  font-weight: 700;
  margin-right: 10px;
}
.challenges_comingSoon {
  border: 1px solid #a6aab3;
  min-height: 476px;
  border-radius: 8px;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.challenges_comingSoon p {
  font-size: 18px;
  font-weight: 600;
  color: #898e99;
}
/* challenges_page */
/* books_page */
.courses_titleBar.books_titleBar {
  margin: 10px 0 20px;
}
.books .courses_innerBox .courses_box .image_side .img_box {
  width: 56px;
  height: 84px;
}
.books .courses_box .content_box .progress-bar {
  margin-top: 8px;
}
.book_complete {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 18px;
  color: #148828;
  margin-top: 8px;
}
/* books_page */
/* simulator_page */
.chapter_box.simulator_box {
  background-color: #ffffff;
}
.account_value_box {
  padding: 24px;
  position: relative;
  background: linear-gradient(rgb(43, 38, 76) 0%, rgb(27, 28, 50) 100%);
  color: #fff;
  margin: -24px -24px 0;
}
.account_value_box > ul {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
}
.account_value_box > ul li {
  display: inline-flex;
  align-items: center;
  padding-right: 7px;
}
.demo_tag {
  background: rgb(86, 83, 254);
  border-radius: 4px;
  padding: 4px;
  font-weight: 600;
  font-size: 12px;
  margin-right: 5px;
}
.demo_tag + img {
  width: 16px;
}
.value_info_box {
  display: flex;
  margin-bottom: 15px;
}
.value_info_box h3 {
  font-weight: 600;
  font-size: 28px;
  line-height: 48px;
}
.value_info_box p {
  font-size: 16px;
  font-weight: 600;
  color: rgb(77, 224, 114);
  margin: 17px 0 0 8px;
}
.metrics_box {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}
.metrics_box > div {
  width: 48%;
  min-height: 72px;
  background: rgb(45, 44, 89);
  border: 1px solid rgb(66, 65, 106);
  border-radius: 8px;
  padding: 12px;
}
.metrics_box > div h5 {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;
}
.metrics_box > div p {
  font-size: 16px;
  font-weight: 600;
  color: rgb(77, 224, 114);
}
.trade_now_btn button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.trade_now_btn button span {
  margin-left: 7px;
}
.active_trad_titleBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 20px;
}
.active_trad_titleBar p {
  font-size: 18px;
  font-weight: 600;
  color: rgb(36, 35, 76);
  opacity: 0.9;
}
.active_trad_titleBar span {
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  color: rgb(107, 114, 128);
}
.asset_container li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
}
.asset_container li .info_box .img_box {
  width: 35px;
  height: 35px;
  background-color: rgb(45, 44, 89);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.asset_container li .info_box {
  flex-grow: 1;
  display: inline-flex;
  align-items: center;
}
.asset_container li .info_box .content_box {
  flex-grow: 1;
  margin-left: 8px;
}
.asset_container li .info_box .img_box img {
  width: 16px;
  height: 16px;
  object-fit: cover;
}
.asset_container li .info_box .content_box .name {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  color: rgb(36, 35, 76);
}
.asset_container li .info_box .content_box .ticker {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: rgb(137, 142, 153);
}
.asset_container .costs_box .cost {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  color: rgb(36, 35, 76);
}
.asset_container .costs_box {
  text-align: right;
}
.asset_container .costs_box .pnl {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}
.asset_container .costs_box .pnl.blue {
  color: rgb(128, 125, 255);
}
.asset_container .costs_box .pnl.green {
  color: rgb(25, 170, 50);
}
.asset_container .costs_box .pnl.red {
  color: rgb(212, 51, 51);
}
/* simulator_page */
/* buy_assets */
.top_header.back_header {
  background-color: #f5f7f9;
}
.search_stock_box {
  width: 100%;
  background: rgb(245, 247, 249);
  border: 1px solid rgb(229, 231, 235);
  border-radius: 8px;
  display: flex;
  margin-bottom: 5px;
}
.search_stock_box:has(input:focus) {
  border-color: rgb(86, 83, 254);
}
.search_stock_box img {
  margin: 0 10px;
  cursor: pointer;
}
.search_stock_box input {
  flex-grow: 1;
  min-height: 35px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: rgb(36, 35, 76);
  background-color: transparent;
  border: none;
  outline: none;
  margin-right: 3px;
}
.search_stock_box input:focus {
  border: none;
  outline: none;
}
.stock_tabBox {
  margin: 13px 0;
  max-width: 500px;
}
.stock_tabBox ul {
  display: flex;
  justify-content: space-evenly;
  border-bottom: 3px solid rgb(238, 238, 255);
}
.stock_tabBox ul li {
  font-size: 14px;
  line-height: 150%;
  color: rgb(36, 35, 76);
  font-weight: 600;
  padding: 16px;
  position: relative;
  border-bottom: 3px solid transparent;
  margin-bottom: -3px;
  width: 50%;
  text-align: center;
}
.stock_tabBox ul li.active {
  border-color: rgb(86, 83, 254);
}
.not_matching {
  text-align: center;
  padding: 50px 0;
}
.not_matching p {
  font-size: 14px;
  opacity: 0.9;
  color: rgb(107, 114, 128);
  margin-top: 8px;
}
/* buy_assets */
/* stock_view_css */
.stocks_name {
  font-size: 16px;
  font-weight: 600;
}
.stocks_view_box .stock_tabBox {
  margin: 0;
}
.stocks_view_box .stock_tabBox ul li {
  width: 50%;
  text-align: center;
}
.view_box {
  margin-bottom: 10px;
  height: 268px;
}
.period_tab {
  width: 100%;
  background-color: rgb(229, 231, 235);
  border: 2px solid rgb(229, 231, 235);
  display: flex;
  border-radius: 6px;
}
.period_tab li {
  flex-grow: 1;
  text-align: center;
  border-radius: 6px;
  padding: 5px 2px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: rgb(107, 114, 128);
  cursor: pointer;
}
.period_tab li.active {
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 1px;
  color: rgb(86, 83, 254);
  font-weight: 600;
}
.how_itWork_box {
  background-color: #fff5e6;
  margin: 10px 0 15px;
  padding: 8px;
  border-radius: 5px;
}
.how_itWork_box button {
  width: 100%;
  background-color: #ffffff;
  border: 0;
  padding: 12px;
  font-weight: 600;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
}
.how_itWork_box .content_box {
  display: flex;
  margin-bottom: 7px;
}
.how_itWork_box .content_box p {
  color: #995c00;
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
}
.how_itWork_box .content_box p span {
  font-weight: 600;
}
.sell_buyBox {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(229, 231, 235);
  border-radius: 8px;
  padding: 16px;
}
.convert_dollarBox {
  display: flex;
  justify-content: space-between;
  color: rgb(36, 35, 76);
}
.convert_dollarBox label {
  font-size: 12px;
  font-weight: 400;
  color: rgb(137, 142, 153);
}
.convert_dollarBox .input_box {
  width: 48%;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(229, 231, 235);
  border-radius: 8px;
  padding: 10px 10px;
}
.convert_dollarBox .input_box input {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: rgb(36, 35, 76);
}
.convert_dollarBox .input_box input {
  border: 0;
  outline: 0;
}
.convert_dollarBox .input_box input:focus {
  border: 0;
  outline: 0;
}
.convert_dollarBox .input_box:has(input:focus) {
  border-color: rgb(86, 83, 254);
}
.availableTotal_list {
  margin: 15px 0;
}
.availableTotal_list ul li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.availableTotal_list ul li p {
  font-size: 14px;
  font-weight: 400;
  color: rgb(137, 142, 153);
}
.availableTotal_list ul li span {
  font-size: 16px;
  font-weight: 600;
  color: rgb(86, 91, 102);
}
.sellBuy_button_container {
  display: flex;
  justify-content: space-between;
}
.sellBuy_button_container button {
  width: 48%;
  background: rgb(212, 51, 51);
  border: 0;
  cursor: pointer;
  color: #fff;
  border-radius: 8px;
  min-height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sellBuy_button_container button:last-child {
  background-color: rgb(25, 170, 50);
}
.sellBuy_button_container button p {
  font-size: 12px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  margin-bottom: 2px;
}
.sellBuy_button_container button h5 {
  font-size: 16px;
  font-weight: 700;
}
.chapter_box.buy_sell_inner {
  padding: 16px 16px 100px;
}
/* stock_view_css */

/* footer */
@media screen and (max-width: 500px) {
  .footer_nav {
    width: 100%;
    max-width: 100%;
    padding: 12px 23px;
  }
  .top_nav_box {
    overflow-x: scroll;
  }
  .stock_tabBox {
    margin: 0 -10px 25px;
  }

  .account_value_box {
    margin: -10px -10px 0;
  }
}

/* book_inner */
.top_header_book_inner {
  position: sticky;
  z-index: 1000;
  max-width: 500px;
  top: 0;
  height: 66px;
  padding: 0 24px;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(224, 228, 233);
}
.header_book_inner_wrapper {
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.book_header_text {
  width: 200px;
}
.book_header_text p {
  font-size: 16px;
  font-weight: 600;
}
.book_inner_top_img_div {
  background: url("../images/book_inner_background.png");
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  padding: 16px 0;
}
.book_inner_top_img img {
  width: 120px;
}
.book_inner_bottom_div {
  background: #f5f7f9;
  padding: 32px 24px;
}
.book_inner_desc_head {
  font-size: 1.25rem;
  line-height: 28px;
  font-weight: 700;
  color: #24234c;
  margin-bottom: 0.5rem;
}
.book_inner_desc_para {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #40444d;
  margin-bottom: 32px;
}
.complete_div {
  background: #e4f2db;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}
.complete_div p {
  font-size: 12px;
  font-weight: 600;
  color: #24234c;
  line-height: 18px;
  text-transform: uppercase;
}
.read_more_btn button, .read_more_btn a {
  display: block;
  width: 100%;
  border-radius: 8px;
  background-color: rgb(86, 83, 254);
  color: rgb(255, 255, 255);
  border: none;
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
  padding: 12px;
  cursor: pointer;
  margin-top: 21px;
}

.book_inner_divider hr {
  margin: 32px 0;
  border-color: #e5e7eb;
  opacity: 0.2;
}
.book_details_top_text {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  color: #24234c;
  margin: 32px 0 16px;
}
.book_details_total {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px 0;
}
.book_details_box {
  background: #fff;
  border: 1px solid #e5e7eb;
  padding: 12px;
  border-radius: 10px;
}
.details_box_top_text p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #24234c;
  margin-bottom: 5px;
}
.details_box_bottom_wrap {
  display: flex;
  align-items: center;
  justify-content: start;
}
.details_box_left_img {
  width: 16px;
  height: 24px;
  margin-right: 6px;
  flex: 0 0 auto;
}
.details_box_left_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.details_box_text p {
  color: #24234c;
  font-weight: 700;
  line-height: 24px;
  font-size: 16px;
}
.book_details_box.top_details_box {
  width: 100%;
}
.book_details_box.bottom_details_box {
  width: 49%;
}
.key_skill_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
  justify-content: start;
}
.key_skill_item {
  background: #ddf;
  padding: 5px 10px;
  border-radius: 5px;
}
.key_skill_item p {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
  color: #24234c;
}
.inside_box {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
  cursor: pointer;
}
.inside_box:not(:last-child) {
  border-bottom: 1px solid #e5e7eb;
}
.inside_box_number {
  width: fit-content;
  margin-right: 15px;
}
.inside_box_number p {
  color: #5653fe;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
}
.inside_box_text {
  width: 100%;
}
.inside_box_text p {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #24234c;
  text-align: left;
}
.inside_box_image {
  width: 25px;
  height: 20px;
}
.inside_box_image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.book_inner_left_arrow {
  height: 20px;
  width: auto;
}
.book_inner_left_arrow img {
  width: auto;
  height: 100%;
}

/* book_inner */

/* book_inner_popup */
.top_header_book_inner.pop_up {
  height: auto;
  padding: 15px 24px;
  border-bottom: 0;
}
.top_header_book_inner.pop_up .header_book_inner_wrapper {
  height: auto;
}
.book_inner_sideBar {
  height: 18px;
  width: 24px;
}
.book_inner_sideBar img {
  width: 100%;
  height: 100%;
}
.top_header_book_inner.pop_up .book_inner_left_arrow {
  height: 16px;
  width: auto;
}
.book_details_popUp {
  padding: 24px 24px;
  background: #f5f7f9;
  transition: all 0.5s ease-in-out;
}
.book_details_popUp_head {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #24234c;
  margin-bottom: 21px;
}
.book_details_popUp_para {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #40444d;
  margin-bottom: 24px;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 50px;
}
.page_number {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #6b7280;
}
.page_arrow {
  width: 45px;
  height: 45px;
  border-radius: 5px;
  background: #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.page_arrow img {
  height: auto;
}
/* book_inner_popup */
/* ---book_lessonList---*/
.book_lessonList_popup {
  /* max-width: 500px;
  margin: 0 auto;
  height: calc(100vh - 50px);
  overflow: scroll;
  padding: 0 24px; */
  position: absolute;
  top: -27px;
  max-width: 500px;
  padding: 0 24px;
  transition: all 0.5s ease-in-out;
}
/* ---book_lessonList---*/

/* Leaderboard */
.book_header_text {
  width: auto;
}
.leaderboard_header {
  padding: 24px;
  position: sticky;
  top: 68px;
  z-index: 999;
  background-color: rgb(36, 35, 76);
  margin-bottom: 10px;
}
.announce_container {
  width: 100%;
  display: flex;
  overflow-x: scroll;
}
.announce_container .announce_imgBox img {
  max-width: 100%;
}
.announce_container .announce_imgBox {
  width: 38px;
  margin-right: 24px;
  flex-shrink: 0;
  cursor: pointer;
}
.announce_container .announce_imgBox:last-child {
  margin-right: 0;
}
.announce_text {
  padding-top: 15px;
  text-align: center;
}
.announce_text h2 {
  font-size: 18px;
  font-weight: 700;
  color: rgb(255, 255, 255);
}
.announce_text > p {
  font-size: 14px;
  font-weight: 400;
  color: rgb(229, 231, 235);
  line-height: 2em;
  margin-bottom: 10px;
}
.announce_scoreBox {
  display: inline-block;
  padding: 4px 12px;
  background: rgb(45, 44, 89);
  border: 1px solid rgb(66, 65, 106);
  border-radius: 4px;
  color: #ffffff;
}
.announce_scoreBox p {
  font-size: 14px;
  font-weight: 400;
}
.announceList_box {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
}
.announceList_box.active {
  background-color: rgb(238, 238, 255);
}
.announceList_box .profile_img {
  width: 43px;
  margin-right: 10px;
}
.announceList_box .profile_img img {
  max-width: 100%;
}
.announceList_box .left {
  display: inline-flex;
  align-items: center;
}
.announceList_box .left .rank {
  width: 24px;
  margin-right: 10px;
}
.announceList_box .left .rank p {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: rgb(36, 35, 76);
}
.announceList_box .left .profile_name p {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  color: rgb(36, 35, 76);
  word-break: break-all;
}
.announceList_box .right p {
  font-size: 16px;
  font-weight: 500;
  color: rgb(36, 35, 76);
}
.announceList_container {
  padding-bottom: 80px;
}
.promotion_zone {
  background-color: rgb(242, 249, 237);
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.promotion_zone p {
  font-size: 18px;
  font-weight: 600;
  color: rgb(25, 170, 50);
  margin: 0 10px;
}
.promotion_zone div.line {
  width: 65px;
  height: 2px;
  background: rgb(25, 170, 50);
}

.demotion_zone {
  background-color: rgb(249, 236, 233);
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.demotion_zone p {
  font-size: 18px;
  font-weight: 600;
  color: rgb(212, 51, 51);
  margin: 0 10px;
}
.demotion_zone div.line {
  width: 65px;
  height: 2px;
  background: rgb(212, 51, 51);
}
.popup_leaderBoard {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 62%);
  z-index: 999;
  display: none;
}
.popupContent_box {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 100%);
  width: 500px;
  max-width: 500px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  border-radius: 8px 8px 0px 0px;
  min-height: 170px;
  transition: all 0.5s ease-in-out;
}

.popupContent_box.active {
  transform: translate(-50%, 0);
}
.popup_text {
  padding-top: 15px;
}
.popup_text h3 {
  font-size: 20px;
  font-weight: 700;
}
.popup_text p {
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 400;
  margin: 16px 0px 24px;
}

@media screen and (max-width: 500px) {
  .popupContent_box {
    width: calc(100% - 20px);
    max-width: calc(100% - 20px);
  }
  .popup_leaderBoard {
    overflow-x: scroll;
  }
}
#question {
  cursor: pointer;
}
/*Leaderboard*/

/* profile_page */
.profile_header {
  padding: 24px;
  background-color: rgb(36, 35, 76);
  color: #ffffff;
  display: flex;
  justify-content: space-between;
}
.profile_header h3 {
  font-size: 20px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  word-break: break-all;
  margin: 7px 0 9px;
}
.profile_header p {
  font-size: 14px;
  color: rgb(255, 255, 255);
  word-break: break-all;
}
.profile_container {
  padding: 24px 0 80px;
}
.profile_section_tag {
  font-size: 18px;
  color: rgb(36, 35, 76);
  font-weight: 600;
  opacity: 0.89;
}
.available_box,
.apprentice_box {
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #e5e7eb;
  margin: 24px 0 16px;
}
.available_box > p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
}
.amount_list {
  display: flex;
  align-items: center;
}
.amount_list li {
  font-size: 16px;
  font-weight: 700;
  margin-right: 5px;
}
.apprentice_box {
  display: flex;
  align-items: center;
}
.apprentice_box .img_box {
  width: 56px;
  margin-right: 10px;
}
.apprentice_box .img_box img {
  max-width: 100%;
}
.apprentice_box .league-title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 3px;
}
.apprentice_box .stat-title {
  font-size: 14px;
  font-weight: 400;
}
.milestones_box_container {
  margin: 24px 0 16px;
}
.milestones_box {
  border-radius: 8px;
  border: 1px solid rgb(229, 231, 235);
  background: rgb(255, 255, 255);
  padding: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}
.milestones_conetntBox {
  flex-grow: 1;
}
.milestones_box .milestones_imgBox {
  width: 64px;
  margin-right: 10px;
  flex-shrink: 0;
}
.milestones_box .milestones_imgBox img {
  width: 100%;
}
.milestones_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.milestones_header h4 {
  font-size: 14px;
  font-weight: 700;
}
.milestones_header p {
  font-size: 12px;
  font-weight: 500;
}
.milestones_conetntBox .progress-bar {
  width: 100%;
  height: 8px;
  position: relative;
  border-radius: 19px;
  background-color: rgb(229, 231, 235);
  overflow: hidden;
  margin: 10px 0;
}
.milestones_conetntBox .progress-bar .line {
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  height: 100%;
  border-radius: 19px;
  background-color: rgb(86, 83, 254);
  transition: width 250ms linear;
}
.milestones_conetntBox .achievement-description {
  font-size: 14px;
  color: rgb(107, 114, 128);
  font-weight: 400;
}
.claim_badge {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  background-color: rgb(86, 83, 254);
  padding: 7.5px;
  border-radius: 4px;
  margin-top: 5px;
  border: 0;
  color: #ffffff;
  cursor: pointer;
}
/* profile_page */

/* ---responsive--- */

@media screen and (max-width: 500px) {
  .footer_nav {
    width: 100%;
    max-width: 100%;
    padding: 12px 23px;
  }
  .top_nav_box {
    overflow-x: scroll;
  }
  .stock_tabBox {
    margin: 0 -10px 25px;
  }

  .account_value_box {
    margin: -10px -10px 0;
  }
}

/* ****investing_challenge**** */
.top_header_book_inner.investing_top .book_inner_sideBar {
  width: auto;
  height: auto;
}
.top_header_book_inner.investing_top .book_inner_sideBar img {
  filter: brightness(0.5);
}
.investing_top_img img {
  width: 100%;
}
.investing_progress_div {
  padding: 24px 24px 32px;
}
.investing_progress_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.investing_progress_head p {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: #24234c;
}
.investing_progress_day_text p {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #6b7280;
}
.investing_progress_day_text p span {
  color: #5653fe;
}
.calender_div {
  border-radius: 16px;
  border: 1px solid #e5e7eb;
  background: #fff;
  padding: 16px 12px;
  margin: 24px 0;
}
.calender_text p {
  color: #24234c;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}
.calender_divider span {
  background-color: rgb(229, 231, 235);
  height: 1px;
  width: 100%;
  display: block;
  margin-top: 12px;
  margin-bottom: 16px;
}
.day_count {
  display: flex;
  overflow-x: scroll;
}
.per_day_select {
  background: #f5f5f5;
  border: 1px solid rgb(245, 245, 245);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border-radius: 12px;
  width: 58px;
  height: 58px;
  flex: 0 0 auto;
  margin-right: 8px;
  cursor: pointer;
}
.day_number p {
  color: #6b7280;
  text-align: center;
  font-size: 16px;
}
.day_select_circle {
  margin-top: 8px;
}
.day_select_circle_outer {
  width: 16px;
  height: 16px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(69, 66, 213);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.day_select_fill {
  width: 10px;
  height: 10px;
  background-color: rgb(69, 66, 213);
  border-radius: 100%;
}
.per_day_select.day_selected {
  border: 1px solid rgb(86, 83, 254);
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 8px 0px;
}
.investing_divider span {
  background-color: rgb(229, 231, 235);
  height: 1px;
  width: 100%;
  display: block;
}
.investing_bottom {
  padding: 24px 24px 32px;
}
.investing_dec_div {
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #e5e7eb;
  background: #fff;
  margin-bottom: 21px;
}
.investing_title p {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: #24234c;
}
.investing_small_para p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-top: 8px;
  margin-bottom: 16px;
  color: #24234c;
}
.investing_small_title p {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #24234c;
}
.investing_big_para p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 4px;
  color: #24234c;
}
.investing_testi_div {
  border-radius: 16px;
  padding: 16px;
  background: #eef;
  position: relative;
}
.investing_testi_head p {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: #24234c;
}
.investing_test_para p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
  color: #24234c;
}
.investing_test_icon {
  position: absolute;
  top: 16px;
  right: 16px;
}

.first_task_main {
  width: calc(100% - 48px);
  min-height: 200px;
  height: auto;
  position: absolute;
  top: 64px;
  bottom: 24px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 16px;
  border-radius: 8px;
  overflow: scroll;
}
.number_of_task {
  margin-left: 8px;
}
.number_of_task p {
  font-size: 12px;
}
.read_chapter_container.start_task {
  overflow: scroll;
}
.read_chapter_topBar.start_task {
  margin-bottom: 15px;
}
.firs_task_top_img_div img {
  width: 100%;
}
.fist_task_inner_wrapper {
  min-height: 100%;
}
.first_start_task_details {
  padding: 24px 24px 0px;
}
.first_task_top_head {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  color: #24234c;
  line-height: 1.5;
}
.first_task_para {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  color: #24234c;
}
.start_tast_btn a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #fff;
  background: #f5f7f9;
  color: #24234c;
  font-size: 16px;
  font-weight: 500;
  margin-top: 8px;
}
.start_task_btn_group {
  margin-top: 18px;
}

.first_task_true_details {
  padding: 24px 24px 0px;
}
.first_tast_top_text {
  display: flex;
  align-items: center;
  justify-content: start;
}
.first_tast_top_text .true_img img {
  max-width: 20px;
}
.first_tast_top_text .true_text {
  font-size: 20px;
  font-weight: 700;
  color: #24234c;
}
.first_tast_top_text .true_text {
  margin-left: 5px;
}
.first_task_text {
  margin-top: 10px;
}
.first_task_true_details .read_more_btn {
  margin-top: 20px;
}
.progress_text_box h3 {
  font-size: 18px;
  line-height: 1.5em;
  color: #24234c;
  margin-bottom: 10px;
}
.progress_text_box > p {
  font-size: 18px;
  font-weight: 400;
  color: #24234c;
  margin-bottom: 20px;
}
.updown_btnbox {
  display: flex;
  justify-content: space-between;
}
.updown_btnbox button {
  width: calc(50% - 5px);
  padding: 15px 0;
  border: none;
  border-radius: 5px;
}
.updown_btnbox button p {
  color: #6b727f;
  font-size: 16px;
  font-weight: 600;
}
.updown_btnbox.sell_buy button p {
  color: #ffffff;
}
.updown_btnbox.sell_buy button:first-child {
  background-color: #c63d23;
}
.updown_btnbox.sell_buy button:last-child {
  background-color: #19aa32;
}
/* ****investing_challenge**** */

/* new_ui_css */
.top_header .right_site {
  display: inline-flex;
  align-items: center;
}
.top_header .right_site .coin_box {
  display: flex;
  align-items: center;
  padding: 16px 10px;
  background-color: #ffc88536;
  border-radius: 8px;
  margin: 0 4px;
}
.top_header .right_site .coin_box p {
  font-size: 14px;
  font-family: "Albert Sans", sans-serif;
  font-weight: 700;
  color: #000000;
  margin-left: 5px;
}
.top_header .right_site .chat_btn {
  padding: 15px;
  border-radius: 8px;
  border: 0;
  background-color: #f5f5f7;
  cursor: pointer;
}
.bar_btn {
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin-left: 5px;
}
.new_chapter_box {
  min-height: 100vh;
  background-color: #ffffff;
  padding: 0 24px 100px;
  position: relative;
}
.new_chapter_box .title_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.my_journey_container {
  margin-bottom: 20px;
}
.new_chapter_box .title_box h4 {
  font-size: 18px;
  font-family: "Albert Sans", sans-serif;
}
.new_chapter_box .title_box p {
  font-size: 14px;
  font-family: "Albert Sans", sans-serif;
  font-weight: 600;
  color: #795af6;
  cursor: pointer;
}
.new_chapter_box .chapter_inner {
  padding: 16px;
  background-color: #f5f5f7;
  border-radius: 12px;
}
.chapter_topBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.chapter_topBar p {
  font-size: 14px;
  font-family: "Albert Sans", sans-serif;
  font-weight: 700;
  background-color: #ffffff;
  padding: 6px 8px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
}
.new_chapter_box .chapter_inner .chapter_title {
  font-size: 18px;
  font-family: "Albert Sans", sans-serif;
}
.new_chapter_box .chapter_inner .chapter_mapBox {
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
}
.new_chapter_box .chapter_inner .chapter_mapBox li:first-child {
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #62d1a2;
  border-radius: 50%;
}
.new_chapter_box .chapter_inner .chapter_mapBox li img {
  max-width: 18px;
}
.new_chapter_box .chapter_inner .chapter_mapBox li {
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d5d6dd;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}
.new_chapter_box .chapter_inner .chapter_mapBox li:not(:first-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  left: calc(-100% + -4px);
  width: 50px;
  height: 1px;
  border-top: 2px dashed #747790;
}
.new_chapter_box .chapter_inner .chapter_mapBox li.active {
  background-color: #795af6;
  box-shadow: 0 0 0 10px #ded7f7;
}
.new_chapter_box .chapter_inner .chapter_mapBox li.active::after {
  border-color: #795af6;
}
.course_box {
  background-color: #f5f5f7;
  width: 220px;
  border-radius: 5px;
  overflow: hidden;
}
.course_box .course_imgBox {
  width: 100%;
  height: 150px;
  position: relative;
}
.course_box .course_imgBox .badge_text {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 14px;
  font-family: "Albert Sans", sans-serif;
  font-weight: 700;
  background-color: #ffffff;
  padding: 6px 8px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
}
.course_box .course_imgBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.course_box .course_content_box {
  padding: 12px;
}
.course_box .course_content_box p {
  font-family: "Albert Sans", sans-serif;
  font-size: 17px;
  font-weight: 600;
}
.new_challenges_box {
  margin: 20px 0;
  background-color: #fff4e7;
  padding: 16px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}
.new_challenges_box .left_site h2 {
  font-size: 22px;
  font-family: "Albert Sans", sans-serif;
  margin-bottom: 5px;
  margin-top: 10px;
}
.new_challenges_box .left_site p {
  font-size: 17px;
  font-family: "Albert Sans", sans-serif;
  color: #747790;
  margin-bottom: 20px;
}
.new_challenges_box .theme1 {
  padding: 11px 33px;
  background-color: #f98c22;
  font-size: 15px;
  font-weight: 600;
  font-family: "Albert Sans", sans-serif;
  color: #ffffff;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
}

.new_challenges_box .wrapper {
  margin: 50px auto 0px;
  position: relative;
  height: 150px;
}

.new_challenges_box .progress-bar {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #ffffff;
  margin: 50px auto 0;
}

.new_challenges_box .card .percent {
  position: relative;
}

.new_challenges_box .card svg {
  position: relative;
  width: 110px;
  height: 110px;
  transform: rotate(-90deg);
}

.new_challenges_box .card svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #f2e1cd;

  stroke-linecap: round;
}
.new_challenges_box .card svg circle:first-of-type {
  stroke-width: 20;
}
.new_challenges_box .card svg circle:last-of-type {
  stroke-dasharray: 625px;
  stroke-dashoffset: calc(625px - (625px * var(--percent)) / 100);
  stroke: #3498db;
  stroke-width: 13;
}

.new_challenges_box .card .number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.new_challenges_box .card .number h3 {
  font-size: 21px;
  font-family: "Albert Sans", sans-serif;
  font-weight: 900;
}

.new_challenges_box .card .title h2 {
  margin: 25px 0 0;
}

.new_challenges_box .card:nth-child(1) svg circle:last-of-type {
  stroke: #f39c12;
}

.new_challenges_box .card:nth-child(2) svg circle:last-of-type {
  stroke: #2ecc71;
}
.books_sliderContainer .course_box {
  width: calc(100% - 10px);
}
.books_sliderContainer .course_box .course_imgBox {
  height: 130px;
}
.books_sliderContainer .course_box .course_content_box {
  padding: 15px 10px;
}
.btn_newtheme {
  max-width: 325px;
  width: 325px;
  background-color: #795af6;
  display: block;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-family: "Albert Sans", sans-serif;
  font-weight: 600;
  padding: 14px 20px;
  border-radius: 8px;
  cursor: pointer;
}
.chapter_btn_box {
  display: flex;
  justify-content: space-between;
}
.menu_btn {
  padding: 16px;
  border: 1px solid #795af6;
  border-radius: 8px;
  cursor: pointer;
}

.course_conatiner {
  margin-top: 30px;
}
.new_bookBox {
  margin-top: 30px;
}
@media screen and (max-width: 500px) {
  .menu_btn {
    margin-left: 10px;
  }
  .top_header .logo_box {
    max-width: 120px;
  }
  .new_chapter_box .chapter_inner .chapter_mapBox li {
    width: 45px;
    height: 45px;
  }
  .new_chapter_box .chapter_inner .chapter_mapBox li:first-child {
    width: 45px;
    height: 45px;
  }
  .new_chapter_box .chapter_inner .chapter_mapBox li img {
    max-width: 16px;
  }
  .new_chapter_box .chapter_inner .chapter_mapBox li:not(:first-child)::after {
    left: calc(-100% + -13px);
  }
  .course_box {
    width: calc(100% - 10px);
  }
  .new_challenges_box {
    margin: 12px 0;
  }
}
@media screen and (max-width: 380px) {
  .course_box .course_imgBox .badge_text {
    font-size: 12px;
  }
  .new_chapter_box {
    padding: 0 16px 100px;
  }
  .new_chapter_box .chapter_inner .chapter_mapBox li:not(:first-child)::after {
    left: calc(-100% + 12px);
    width: 29px;
    border-top-width: 1.5px;
  }
  .course_box .course_imgBox {
    height: 121px;
  }
  .books_sliderContainer .course_box {
    width: calc(100% - 7px);
  }
  .books_sliderContainer .course_box .course_imgBox {
    height: 106px;
  }
  .course_box .course_content_box p {
    font-size: 15px;
  }
  .new_challenges_box .left_site h2 {
    font-size: 20px;
  }
  .new_challenges_box .left_site p {
    font-size: 15px;
  }
}
/* new_ui_css */
/* / right_top_left_navbar / */

.top_navbar {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  overflow: hidden;
  pointer-events: none;
}
.top_navbar.active {
  pointer-events: all;
}
.top_navbar ul {
  margin: 0 auto;
  max-width: 455px;
  background-color: #ffffff;
  border: 1px solid #eeeef2;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 0 2px 0px #ededed;
  transform: translateX(1001%);
  transition: all 0.5s ease-in-out;
  z-index: 999;
}
.top_navbar.active ul {
  transform: translateX(0);
}
.top_navbar .nav li {
  padding: 15px;
  border-radius: 8px;
  font-family: "Albert Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.top_navbar .nav li img {
  margin-right: 12px;
  transition: all 0.2s ease-in-out;
}
.top_navbar .nav li:hover,
.top_navbar .nav li.active {
  color: #795af6;
  background-color: #f5f5f7;
}
.top_navbar .nav li:hover img,
.top_navbar .nav li.active img {
  filter: brightness(0) saturate(100%) invert(35%) sepia(90%) saturate(3601%)
    hue-rotate(238deg) brightness(103%) contrast(93%);
}
@media screen and (max-width: 500px) {
  .top_navbar {
    padding: 0 20px;
  }
}
/* / right_top_left_navbar / */

/****Investment_inner****/
.finish_lesson_content p svg {
  margin: 0 2px;
}
.finish_lesson_inner.investment_inner {
  justify-content: space-between;
  overflow: auto;
}
.finish_lesson_inner.investment_inner .attempt_container {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}
.finish_lesson_inner.investment_inner .attempt_container .box {
  width: calc(50% - 10px);
  border-radius: 8px;
  border: 1px solid rgb(229, 231, 235);
  background: rgb(255, 255, 255);
  text-align: center;
  padding: 15px;
}
.finish_lesson_inner.investment_inner .attempt_container .box p {
  font-size: 16px;
  font-family: "Albert Sans", sans-serif;
  margin-bottom: 4px;
}
.finish_lesson_inner.investment_inner .attempt_container .box h5 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.finish_lesson_inner.investment_inner .attempt_container .box h5 img {
  margin-right: 5px;
}
.investment_btn button {
  width: 100%;
}
.investment_question_inner {
  width: calc(100% - 48px);
  min-height: 200px;
  height: auto;
  position: absolute;
  top: 64px;
  bottom: 24px;
  border-radius: 8px;
  overflow: auto;
  padding: 10px 10px;
}
.investment_question_option {
  border-radius: 8px;
  border: 1px solid rgb(255, 255, 255);
  background: rgb(255, 255, 255);
  padding: 20px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
}
.investment_question_inner h2 {
  font-size: 25px;
  font-family: "Albert Sans", sans-serif;
  margin-bottom: 20px;
}
.investment_question_option p {
  font-size: 15px;
  font-weight: 500;
}
.investment_question_option .answer_status > div {
  display: none;
}
.investment_question_option .correct_box {
  width: 18px;
  height: 18px;
  background-color: rgb(25, 170, 50);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.investment_question_option .incorrect_box {
  width: 18px;
  height: 18px;
  background-color: #d43333;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.investment_question_option.incorrect_box {
  border-color: #d43333;
}
.investment_question_option.incorrect_box .answer_status .incorrect_box {
  display: inline-flex;
}
.investment_question_option.correct_box {
  border-color: rgb(25, 170, 50);
}
.investment_question_option.correct_box .answer_status .correct_box {
  display: inline-flex;
}
.read_chapter_container.start_task {
  overflow: auto;
}
.incorrect_popup,
.correct_popup {
  flex-wrap: wrap;
}
.incorrect_popup > p {
  font-size: 15px;
  font-weight: 500;
  margin-top: 5px;
}
.error_btn_warper {
  width: 100%;
  margin-top: 10px;
}
.error_btn_warper button {
  width: 100%;
  background: rgb(212, 51, 51);
  color: #ffffff;
  border: 0;
  padding: 12px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
}
.correct_btn_warper {
  width: 100%;
  margin-top: 10px;
}
.correct_btn_warper button {
  width: 100%;
  background: rgb(25, 170, 50);
  color: #ffffff;
  border: 0;
  padding: 12px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
}
.popup_leaderBoard {
  overflow: auto;
}
.sessionClose_popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 62%);
  z-index: 999;
  display: none;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
.sessionClose_popup.show {
  display: flex;
}
.sessionClose_popup .sessionClose_popup_content {
  width: calc(100% - 10px);
  max-width: 500px;
  background: rgba(255, 255, 255, 0.98);
  border-radius: 8px;
  padding: 24px;
  transform: translateY(-1000%);
  transition: all 0.5s ease-in-out;
  position: fixed;
  bottom: 0;
}
.sessionClose_popup .sessionClose_popup_content.active {
  transform: translateY(0);
}

.sessionClose_popup_text {
  text-align: center;
}
.sessionClose_popup_text p {
  margin-bottom: 19px;
}
.sessionClose_popup_text .read_more_btn:first-of-type {
  margin-bottom: 10px;
}
.sessionClose_popup_text .read_more_btn:first-of-type button {
  background-color: #f98c22;
}
.sessionClose_popup_text .read_more_btn button {
  font-size: 14px;
}
.sessionClose_popup_text h3 {
  font-weight: 600;
  font-size: 20px;
  color: rgb(36, 35, 76);
  margin-bottom: 5px;
}
#sessionClose_popup {
  cursor: pointer;
}
.welcome_backPopup .coin_box {
  text-align: right;
}
.welcome_backPopup .coin_box p {
  display: inline-block;
  margin: 0;
  vertical-align: super;
}
.welcome_backPopup .task_box {
  border: 1px solid #eeee;
  border-radius: 10px;
  padding: 10px 17px 18px;
  margin-bottom: 10px;
  text-align: left;
}
.welcome_backPopup .task_box > span {
  display: table;
  font-size: 12px;
  background-color: #f98c22;
  color: #ffffff;
  padding: 5px 9px;
  border-radius: 3px;
  margin-top: -19px;
}
.welcome_backPopup .task_box > p {
  margin-bottom: 0;
}
.welcome_backPopup .task_box > h4 {
  margin: 8px 0 5px;
}
.welcome_backPopup .read_more_btn button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.welcome_backPopup .read_more_btn button img {
  margin: 0 5px;
}
@media screen and (max-width: 500px) {
  .sessionClose_popup .sessionClose_popup_content {
    padding: 24px 17px;
  }
  .finish_lesson_content h2 {
    font-size: 20px;
  }
  .finish_lesson_content p {
    font-size: 15px;
  }
  .investment_question_inner h2 {
    font-size: 23px;
  }
  .investment_question_inner {
    padding: 2px;
  }
}
/****Investment_inner****/

/* ****chat_ui**** */
.chat_input_container {
  width: 100%;
  max-width: 500px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  padding: 12px 35px;
  display: flex;
  align-items: center;
  background-color: #f5f7f9;
}
.chat_input_container .input_chat {
  flex-grow: 1;
}
.chat_input_container .input_chat textarea {
  width: 100%;
  height: fit-content;
  min-height: 30px;
  resize: none;
  border-radius: 23px;
  padding: 13px 16px 12px;
  font-size: 16px;
  font-family: "Albert Sans", sans-serif;
  border: 1px solid #bbbbbb;
  outline: 0;
}
.chat_input_container .input_chat textarea:focus {
  border-color: #795af6;
}
.chat_input_container .chat_sendBox {
  margin-left: 10px;
}
.chat_input_container .chat_sendBox button {
  width: 45px;
  height: 45px;
  background-color: #fff;
  border: 0;
  border-radius: 50%;
  border: 1px solid #bbbbbb;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.chat_input_container .chat_sendBox button:hover {
  border-color: #795af6;
}
.chat_input_container .chat_sendBox button img {
  filter: brightness(0) saturate(100%) invert(35%) sepia(90%) saturate(3601%)
    hue-rotate(238deg) brightness(103%) contrast(93%);
}
.ai_text p {
  width: 100%;
  max-width: fit-content;
  display: inline-block;
  background-color: #795af6;
  color: #ffffff;
  padding: 12px 15px;
  border-radius: 0px 15px 15px 15px;
  font-size: 16px;
}
.your_text p {
  width: 100%;
  max-width: fit-content;
  display: inline-block;
  background-color: rgb(255, 255, 255);
  font-size: 16px;
  color: rgb(36, 35, 76);
  padding: 12px 15px;
  border-radius: 0 15px 15px 15px;
  text-align: left;
}

.chat_box {
  display: table;
}

.chat_box .ai_text {
  float: left;
  text-align: left;
}
.chat_box .your_text {
  float: left;
  text-align: left;
}
.chat_header {
  margin-bottom: 40px;
}
.chat_box > div > div {
  width: 100%;
  margin-bottom: 15px;
}
.chat_header .ai_img_box {
  width: 70px;
  height: 70px;
  display: inline-block;
}
.chat_header {
  text-align: center;
}
.chat_header .ai_img_box img {
  width: 100%;
  height: 100%;
}
.chat_header h3 {
  font-size: 23px;
  margin: 10px 0;
}
.chat_header p {
  font-size: 15px;
}
@media screen and (max-width: 360px) {
  .ai_text p {
    font-size: 15px;
  }
}
/* ****chat_ui**** */

/* / trade_now_toltip /? */
.btn_theme_1 {
  position: relative;
}

.btn_theme_1 .toltip_box {
  position: absolute;
  width: 100%;
  max-width: 230px;
  bottom: calc(100% + 20px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  color: rgb(36, 35, 76);
  border-radius: 10px;
  padding: 10px 17px;
  text-align: left;
}
.btn_theme_1 .toltip_box::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-top: 15px solid #ffffff;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}
.btn_theme_1 .toltip_box h3 {
  font-size: 15px;
  margin-bottom: 5px;
}
.btn_theme_1 .toltip_box p {
  font-size: 12px;
}
/* / trade_now_toltip / */

/* / chapter_drop_down / */
.button_chapter {
  position: relative;
  font-size: 14px;
  font-family: "Albert Sans", sans-serif;
  font-weight: 700;
  background-color: #ffffff;
  padding: 6px 8px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  cursor: pointer;
}
.dropdown_menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 888;
  display: none;
  min-width: 150px;
  font-size: 16px;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown_menu.dropdown_menu_active {
  display: block;
}

.dropdown_menu li {
  display: block;
  width: 100%;
  padding: 6px 9px;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.dropdown_menu li:hover {
  background-color: #fff3e5;
}
/* / chapter_drop_down / */

/* / verfication submit_form / */
.submit_form {
  background-color: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  padding: 16px;
}
.submit_form > div {
  margin: 10px 0;
}
.submit_form label {
  display: block;
  margin-bottom: 10px;
}
.submit_form input:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.submit_form span {
  font-size: 15px;
  color: #6c6c6c;
  margin-top: 4px;
  display: inline-block;
}

.submit_form input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.submit_form .read_more_btn {
  margin-top: 30px;
}
.new_btn {
  display: block;
  background-color: #dd524c;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
  max-width: 410px;
  margin: 30px auto 50px;
  text-align: center;
  font-size: 19px;
  font-weight: 600;
  border-radius: 5px;
  padding: 12px 10px;
  box-shadow: 0 0 10px 10px #f1f1f1;
  border: none;
  cursor: pointer;
}

.text_h {
  color: #dc534c;
  font-size: 22px;
  margin: 15px 0;
}

.text_p {
  margin: 15px 0;
  line-height: 1.5em;
}

.text_h4 {
  margin-top: 30px;
  margin-bottom: 17px;
}
.intl_box > div{
  width: 100% !important;
}

/* /end verfication submit_form / */


/* add_css loader */
.ytp-spinner {
  position: relative;
  width: 64px;
  transform: translate(-50%, -50%);
  z-index: 18;
  pointer-events: none;
}

.ytp-spinner-container {
  pointer-events: none;
  position: absolute;
  width: 100%;
  padding-bottom: 100%;
  top: 50%;
  left: 50%;
  margin-top: -50%;
  margin-left: -50%;
  animation: ytp-spinner-linspin 1568.23529647ms linear infinite;
  -webkit-animation: ytp-spinner-linspin 1568.23529647ms linear infinite;
}

.ytp-spinner-rotator {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
  animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
}

.ytp-spinner-left {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

.ytp-spinner-right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.ytp-spinner-left {
  right: 49%;
}

.ytp-spinner-right {
  left: 49%;
}

.ytp-spinner-circle {
  box-sizing: border-box;
  position: absolute;
  width: 200%;
  height: 100%;
  border-style: solid;
  border-color: #5653fe #5653fe transparent;
  border-radius: 50%;
  border-width: 6px;
}

.ytp-spinner-left .ytp-spinner-circle {
  left: 0;
  right: -100%;
  border-right-color: transparent;
  -webkit-animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
  animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
}

.ytp-spinner-right .ytp-spinner-circle {
  left: -100%;
  right: 0;
  border-left-color: transparent;
  -webkit-animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
  animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
}

@-webkit-keyframes ytp-spinner-linspin {
  to {
      -webkit-transform: rotate(360deg);
  }
}

@keyframes ytp-spinner-linspin {
  to {
      transform: rotate(360deg);
  }
}

@-webkit-keyframes ytp-spinner-easespin {
  12.5% {
      -webkit-transform: rotate(135deg);
  }

  25% {
      -webkit-transform: rotate(270deg);
  }

  37.5% {
      -webkit-transform: rotate(405deg);
  }

  50% {
      -webkit-transform: rotate(540deg);
  }

  62.5% {
      -webkit-transform: rotate(675deg);
  }

  75% {
      -webkit-transform: rotate(810deg);
  }

  87.5% {
      -webkit-transform: rotate(945deg);
  }

  to {
      -webkit-transform: rotate(1080deg);
  }
}

@keyframes ytp-spinner-easespin {
  12.5% {
      transform: rotate(135deg);
  }

  25% {
      transform: rotate(270deg);
  }

  37.5% {
      transform: rotate(405deg);
  }

  50% {
      transform: rotate(540deg);
  }

  62.5% {
      transform: rotate(675deg);
  }

  75% {
      transform: rotate(810deg);
  }

  87.5% {
      transform: rotate(945deg);
  }

  to {
      transform: rotate(1080deg);
  }
}

@-webkit-keyframes ytp-spinner-left-spin {
  0% {
      -webkit-transform: rotate(130deg);
  }

  50% {
      -webkit-transform: rotate(-5deg);
  }

  to {
      -webkit-transform: rotate(130deg);
  }
}

@keyframes ytp-spinner-left-spin {
  0% {
      transform: rotate(130deg);
  }

  50% {
      transform: rotate(-5deg);
  }

  to {
      transform: rotate(130deg);
  }
}

@-webkit-keyframes ytp-right-spin {
  0% {
      -webkit-transform: rotate(-130deg);
  }

  50% {
      -webkit-transform: rotate(5deg);
  }

  to {
      -webkit-transform: rotate(-130deg);
  }
}

@keyframes ytp-right-spin {
  0% {
      transform: rotate(-130deg);
  }

  50% {
      transform: rotate(5deg);
  }

  to {
      transform: rotate(-130deg);
  }
}

.preloader_flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-weight: bold;
  background: #ffffff;
}

/* add_css */